import React from 'react';
import { Box } from '@mui/material';
import PatientDemographicForm from 'pages/Dashboard/components/PatientDempgraphicForm';
import PatientInviteForm from 'pages/Dashboard/components/PatientInviteForm';
import PatientInfoFormHeader from 'pages/Dashboard/components/PatientInfoFormHeader';

export interface PatientInfoFormProps {
  isDemographicForm: boolean;
  setIsDemographicForm: (value: boolean) => void;
}

export default function PatientInfoForm({
  isDemographicForm,
  setIsDemographicForm,
}: PatientInfoFormProps) {
  return (
    <Box sx={{ width: 0.9 }}>
      <PatientInfoFormHeader
        isDemographicForm={isDemographicForm}
        setIsDemographicForm={setIsDemographicForm}
      />
      {isDemographicForm ? (
        <PatientDemographicForm />
      ) : (
        <PatientInviteForm />
      )}
    </Box>
  );
}
