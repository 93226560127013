import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toast, { ToastProps } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import { Designation, EProfessions, Practice } from 'types/Entitites';
import { addressSerializar } from 'utils/phoneUtils';
import BorderTableRow from 'components/Common/BorderTableRow';
import { useQuery } from 'react-query';
import { Box, Link, TextField, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ContentLoader from 'components/Common/ContentLoader';
import {
  fetchPlatformPracticeList,
  fetchPracticeProviderList,
} from 'pages/Dashboard/services/addpatient.services';
import ActionsMenu from 'pages/AdminPortal/Tabs/Practice/ActionMenu';

export default function PracticeList() {
  const { http } = useHttp();

  const [selectedPractice, setSelectedPractice] = React.useState({ id: '', name: '' });
  const [searchTerm, setSearchTerm] = React.useState('');
  const [typingTimeout, setTypingTimeout] = React.useState<NodeJS.Timeout | null>(null);
  const [toastProps, setToastProps] = React.useState<ToastProps | undefined>();

  const {
    data: practiceList,
    isFetching: practiceListFetching,
    refetch: refetchPracticeList,
  } = useQuery('practices-list', {
    queryFn: fetchPlatformPracticeList(http.get, searchTerm ? `practice-name=${searchTerm}` : ''),
  });

  const {
    data: providersList,
    isFetching: providersListFetching,
    refetch: refetchProvidersList,
  } = useQuery('practices-list', {
    queryFn: fetchPracticeProviderList(
      http.get,
      selectedPractice.id,
      searchTerm ? `provider-name=${searchTerm}` : '',
    ),
    enabled: !!selectedPractice.id,
  });

  const practiceListResponse = practiceList?.practices ?? [];

  const providersListResponse = providersList?.providers ?? [];

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastProps(undefined);
  };

  const onPracticeSelect = (practice: Practice) => {
    setSearchTerm('');
    setSelectedPractice(practice);
  };

  const onPracticeListSelect = () => {
    setSearchTerm('');
    setSelectedPractice({ id: '', name: '' });
  };

  React.useEffect(() => {
    if (selectedPractice.id) {
      refetchProvidersList();
    }
    else {
      refetchPracticeList();
    }
  }, [refetchPracticeList, refetchProvidersList, selectedPractice.id]);

  const breadcrumbs = [
    <Link underline='hover' onClick={onPracticeListSelect} sx={{ cursor: 'pointer' }}>
      Practice list
    </Link>,
    ...(selectedPractice.id
      ? [
        <Typography key='3' sx={{ color: 'text.primary' }}>
          {selectedPractice.name}
        </Typography>,
      ]
      : []),
  ];

  const tableCells = React.useMemo(() => {
    if (selectedPractice.id) {
      return ['Provider name', 'Provider email', 'Designation', 'Actions'];
    }

    return ['Practice name', 'Address', 'Added on', 'Actions'];
  }, [selectedPractice.id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value); // Update the state

    // Clear the previous timeout if it exists
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to debounce the search action
    const timeout = setTimeout(() => {
      selectedPractice.id
        ? refetchProvidersList()
        : refetchPracticeList(); // Perform search after debounce delay
    }, 300); // Delay of 500ms

    setTypingTimeout(timeout); // Save the timeout ID to clear it later
  };

  const noData = selectedPractice.id
    ? providersListResponse.length === 0
    : practiceListResponse.length === 0;

  return (
    <>
      <Box display='flex' justifyContent='space-between' mb={3}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          {breadcrumbs}
        </Breadcrumbs>
        <TextField
          label={selectedPractice.id ? 'Search provider' : 'Search practice'}
          variant='outlined'
          fullWidth
          value={searchTerm}
          onChange={handleChange}
          margin='normal'
          sx={{ width: 300, m: 0 }}
        />
      </Box>
      <ContentLoader
        isFetching={practiceListFetching || providersListFetching}
        isError={false}
        minHeight={400}
        noDataText={noData
          ? `No ${selectedPractice.id ? 'provider' : 'practice'} found`
          : ''}
      >
        <TableContainer>
          <Table aria-label='provider-requests-table' size='small'>
            <TableHead>
              <TableRow>
                {tableCells.map((cell) => (
                  <TableCell key={cell}>{cell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {selectedPractice.id ? (
              <TableBody>
                {providersListResponse.map((provider) => (
                  <BorderTableRow key={`provider-practice-${provider.id}`}>
                    <TableCell>
                      {provider.firstName}
                      {' '}
                      {provider.lastName}
                    </TableCell>
                    <TableCell>{provider.email}</TableCell>
                    <TableCell>{EProfessions[provider.designation as Designation]}</TableCell>
                    <TableCell>
                      <ActionsMenu
                        practiceId={selectedPractice?.id}
                        providerId={provider.id}
                        providerEmail={provider.email}
                        refetchProvidersList={refetchProvidersList}
                      />
                    </TableCell>
                  </BorderTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {practiceListResponse.map((practice) => (
                  <BorderTableRow key={`provider-practice-${practice.id}`}>
                    <TableCell>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onPracticeSelect(practice)}
                        underline='hover'
                      >
                        {practice.name}
                      </Link>
                    </TableCell>
                    <TableCell>{addressSerializar(practice.address)}</TableCell>
                    <TableCell>
                      {new Date(practice.timeAudit?.createdAt as string).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <ActionsMenu practiceId={selectedPractice?.id} />
                    </TableCell>
                  </BorderTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </ContentLoader>
      {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
    </>
  );
}
