import Container from '@mui/material/Container';
import BrandingBorder from 'components/Layouts/BrandingBorder';
import LandingPad from 'pages/Authentication/components/LandingPad';
import Contact from 'pages/Authentication/Contact';
import Login from 'pages/Authentication/Login';
import Logout from 'pages/Authentication/Logout';
import Signup from 'pages/Authentication/Signup';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

export default function Router() {
  return (
    <Container maxWidth='lg'>
      <BrandingBorder>
        <Routes>
          <Route path='contact-us' element={<Contact />} />
          <Route path='jarvis-login' element={<Login />} />
          <Route path='login' element={<Login />} />
          <Route path='landing-pad' element={<LandingPad />} />
          <Route path='logout' element={<Logout />} />
          <Route path='signup' element={<Signup />} />
        </Routes>
      </BrandingBorder>
    </Container>
  );
}
