import React, { useEffect, useMemo, useState } from 'react';
import { Box, Drawer, Typography, Tabs, Tab, Tooltip as MuiTooltip, Slider } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, Text } from 'recharts';
import Theme from 'theme';
import { PatientMedications } from 'pages/Dashboard/types/medication.types';
import { formatTickLabel, generateTicks, minDistanceCalculation } from 'utils/medicationChartTimelineUtil';
import { googleDateToDateString } from 'utils/dateUtils';

interface ChartDrawerProps {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  medicationTimeLines: PatientMedications;
  handleSelectedMedication: (medicationId: string,
    medicationName: string,
    medicationMetaId: string,
    patientId: string) => void;
}

type ChartDataPoint = {
  date: number | null;
  name: string;
  startDate?: number;
  endDate?: number;
  active?: number;
};

const convertToDate = (date: { year: number; month?: number; day: number }) => {
  if (!date || !date.year || !date.day || !date.month) {
    return null;
  }
  return new Date(date.year, date.month - 1, date.day);
};

interface CustomTooltipProps {
  active?: boolean;
  label?: string
  activeTooltipData: {
    hoveredPoints: ChartDataPoint[] | null;
    tooltipMedicationName: string | null;
  }
}

function CustomTooltip({ active, activeTooltipData, label }: CustomTooltipProps) {
  if (active && activeTooltipData && activeTooltipData.tooltipMedicationName) {
    const { tooltipMedicationName, hoveredPoints } = activeTooltipData;
    const hoveredDate = label ? new Date(label) : null;
    // Find the point that matches the hovered date
    const matchingPoint = hoveredPoints?.find((point) => {
      if (point?.startDate && point?.endDate) {
        return hoveredDate && hoveredDate >= new Date(point.startDate) && hoveredDate <= new
        Date(point.endDate);
      }
      return false;
    });

    const formattedStartDate = matchingPoint?.startDate
      && googleDateToDateString(
        {
          year: new Date(matchingPoint.startDate).getFullYear(),
          month: new Date(matchingPoint.startDate).getMonth() + 1,
          day: new Date(matchingPoint.startDate).getDate(),
        },
        false,
      );

    const formattedEndDate = matchingPoint?.endDate
      && googleDateToDateString(
        {
          year: new Date(matchingPoint.endDate).getFullYear(),
          month: new Date(matchingPoint.endDate).getMonth() + 1,
          day: new Date(matchingPoint.endDate).getDate(),
        },
        false,
      );

    return (
      <div
        className='custom-tooltip'
        style={{
          position: 'relative',
          transform: 'translate(-55%, -145%)',
          backgroundColor: '#F1F1F1',
          borderRadius: '4px',
          padding: '10px',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
          fontSize: '14px',
          color: '#1E2731',
          maxWidth: '200px',
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 'bold',
            color: '#111827',
            fontSize: '14px',
          }}
        >
          {tooltipMedicationName}
        </p>
        {matchingPoint && (
          <p style={{ margin: 0, color: '#1E2731' }}>
            {`${formattedStartDate} - ${formattedEndDate}`}
          </p>
        )}
        <div
          style={{
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid #F1F1F1',
            borderBottom: 'none',
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '-9px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 0,
            height: 0,
            borderLeft: '9px solid transparent',
            borderRight: '9px solid transparent',
            borderTop: '9px solid #F1F1F1',
            borderBottom: 'none',
          }}
        />
      </div>
    );
  }
  return null;
}

interface CustomYAxisTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
  medicationTimeLines: PatientMedications;
  handleSelectedMedication: (medicationId: string,
    medicationName: string,
    medicationMetaId: string,
    patientId: string) => void;
}

function addSuperscriptR(str: string) {
  return str.replace(/\(([^)]+)\)$/, '($1®)');
}

function CustomYAxisTick({ x, y, payload, medicationTimeLines,
  handleSelectedMedication }: CustomYAxisTickProps) {
  const [isHovered, setIsHovered] = useState(false);
  const originalLabel = payload?.value || '';
  const label = addSuperscriptR(originalLabel);
  const medication = medicationTimeLines.patientMedicationTimelines
    ?.find((med) => med.medicationMetaName === originalLabel);
  const isPsychMedication = medication?.isPsychMedication;
  const textColor = isPsychMedication ? '#33739D' : '#8E4C7D';

  const maxLines = 2;
  const maxCharsPerLine = 18;
  const words = label.split(' ');
  const lines: string[] = [];
  let currentLine = '';

  for (const word of words) {
    if ((`${currentLine} ${word}`).trim().length <= maxCharsPerLine) {
      currentLine = `${currentLine} ${word}`.trim();
    }
    else {
      lines.push(currentLine);
      currentLine = word;

      if (lines.length === maxLines) {
        break;
      }
    }
  }

  if (lines.length < maxLines && currentLine) {
    lines.push(currentLine);
  }

  const showEllipsis = words.join(' ').length > lines.join(' ').length;
  const handleClick = () => {
    handleSelectedMedication(
      medication?.patientMedicationId as string,
      originalLabel,
      medication?.medicationMetaId as string,
      medicationTimeLines?.patientId,
    );
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <MuiTooltip title={label} placement='top'>
        <text
          x={0}
          y={0}
          textAnchor='end'
          fill={textColor}
          fontSize={14}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100px',
            whiteSpace: 'pre',
            cursor: 'pointer',
            textDecoration: isHovered ? 'underline' : 'none',
          }}
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {lines.map((line, lineIndex) => (
            <tspan
              key={line}
              x={0}
              dy={lineIndex * 15}
            >
              {line}
              {lineIndex === maxLines - 1 && showEllipsis ? '...' : ''}
            </tspan>
          ))}
        </text>
      </MuiTooltip>
    </g>
  );
}

function renderLegendContent(selectedTab: number) {
  if (selectedTab === 0) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'inline-block', width: 12, height: 12, backgroundColor: '#33739D', borderRadius: '50%', marginRight: 15 }} />
        <Text style={{ color: '#000000', fontSize: 16 }}>Psych Medications</Text>
      </div>
    );
  }
  if (selectedTab === 1) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'inline-block', width: 12, height: 12, backgroundColor: '#8E4C7D', borderRadius: '50%', marginRight: 15 }} />
        <Text style={{ color: '#000000', fontSize: 16 }}>Other Medications</Text>
      </div>
    );
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'inline-block', width: 12, height: 12, backgroundColor: '#33739D', borderRadius: '50%', marginRight: 15 }} />
        <Text style={{ color: '#000000', fontSize: 16, marginRight: 40 }}>Psych Medications</Text>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'inline-block', width: 12, height: 12, backgroundColor: '#8E4C7D', borderRadius: '50%', marginRight: 15 }} />
        <Text style={{ color: '#000000', fontSize: 16 }}>Other Medications</Text>
      </div>
    </>
  );
}

function getNoTimelineCount(
  selectedTab: number,
  noTimelinePsychCount: number,
  noTimelineOtherCount: number,
  noTimelineBothCount: number,
) {
  if (selectedTab === 0) {
    return noTimelinePsychCount;
  }
  if (selectedTab === 1) {
    return noTimelineOtherCount;
  }
  return noTimelineBothCount;
}

function MedicationChartDrawer({ isOpen, toggleDrawer, medicationTimeLines,
  handleSelectedMedication }: ChartDrawerProps) {
  const [selectedTab, setSelectedTab] = useState(2);
  const [boxHeight, setBoxHeight] = useState(0);
  const [activeTooltipData, setActiveTooltipData] = useState<{
    tooltipMedicationName: string | null;
    hoveredPoints: ChartDataPoint[] | null;
  }>({
    tooltipMedicationName: null,
    hoveredPoints: null,
  });

  const handleSelectedTab = (event: React.SyntheticEvent, tab: number) => {
    event.preventDefault();
    setSelectedTab(tab);
  };

  const chartData = useMemo(() => {
    const allData: ChartDataPoint[] = [];

    medicationTimeLines?.patientMedicationTimelines?.forEach((medication) => {
      const { medicationMetaName, timelines } = medication;

      timelines.forEach((timeline) => {
        const { startDate, endDate } = timeline;
        const start = convertToDate(startDate);
        const end = convertToDate(endDate);

        if (start && end) {
          const currentDate = new Date(start);
          while (currentDate <= end) {
            allData.push({
              date: currentDate.getTime(),
              name: medicationMetaName,
              active: 1,
              startDate: new Date(start).getTime(),
              endDate: new Date(end).getTime(),
            });
            currentDate.setDate(currentDate.getDate() + 30);
          }
          allData.push({
            date: null,
            name: medicationMetaName,
            active: 0,
          });
        }
      });
    });

    return allData;
  }, [medicationTimeLines]);

  const allDates = chartData.map((item) => item.date).filter((date):
  date is number => date !== null);

  const minDate = useMemo(() => new Date(Math.min(...allDates)), [allDates]);
  const maxDate = useMemo(() => new Date(Math.max(...allDates)), [allDates]);

  const ticks = useMemo(() => generateTicks(minDate, maxDate), [minDate, maxDate]);

  // Set the default slider values to the most recent 12 years of data
  const twelveYearsAgo = new Date(ticks[ticks.length - 1]);
  twelveYearsAgo.setFullYear(twelveYearsAgo.getFullYear() - 12);
  const initialDomainValue = [Math.max(ticks[0], twelveYearsAgo.getTime()),
    ticks[ticks.length - 1]];

  const [domainValue, setDomainValue] = React.useState<number[]>(initialDomainValue);

  const minDistance = useMemo(() => {
    const totalYears = maxDate.getFullYear() - minDate.getFullYear();
    return minDistanceCalculation(totalYears);
  }, [minDate, maxDate]);

  const updateYAxisBackground = () => {
    const allAxis = document.querySelectorAll('.recharts-yAxis');

    allAxis?.forEach((axis) => {
      const orientation = axis
        .querySelector('.recharts-cartesian-axis-tick-line')
        ?.getAttribute('orientation');

      // Adding a rect
      const rect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect',
      );
      const yAxisheight = axis.getBoundingClientRect().height;

      rect.setAttribute('x', '0');
      rect.setAttribute('y', '0');
      rect.setAttribute('width', `${160}`);
      rect.setAttribute('height', `${yAxisheight}`);
      rect.setAttribute('fill', 'white');
      rect.setAttribute('class', `y-axis-rect-${orientation}`);

      axis.insertBefore(rect, axis.firstChild);
    });
  };

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], ticks[ticks.length - 1] - minDistance);
        setDomainValue([clamped, clamped + minDistance]);
      }
      else {
        const clamped = Math.max(newValue[1], ticks[0] + minDistance);
        setDomainValue([clamped - minDistance, clamped]);
      }
    }
    else {
      setDomainValue(newValue as number[]);
    }
    updateYAxisBackground();
  };

  const filteredData = useMemo(() => {
    let data = [];

    if (selectedTab === 0) {
      data = chartData.filter((item) => medicationTimeLines.patientMedicationTimelines
        .some((medication) => medication.isPsychMedication && medication
          .medicationMetaName === item.name));
    }
    else if (selectedTab === 1) {
      data = chartData.filter((item) => medicationTimeLines.patientMedicationTimelines
        .some((medication) => !medication.isPsychMedication && medication
          .medicationMetaName === item.name));
    }
    else {
      data = chartData;
    }
    data.unshift({ date: null, name: '  ', active: 0 });
    return data;
  }, [chartData, selectedTab, medicationTimeLines]);

  const psychCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => medication.isPsychMedication).length, [medicationTimeLines]);
  const otherCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => !medication.isPsychMedication).length, [medicationTimeLines]);
  const bothCount = medicationTimeLines.patientMedicationTimelines?.length;

  const noTimelinePsychCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => medication.isPsychMedication && medication
      .timelines.length === 0).length, [medicationTimeLines]);
  const noTimelineOtherCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => !medication.isPsychMedication && medication
      .timelines.length === 0).length, [medicationTimeLines]);
  const noTimelineBothCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => medication.timelines.length === 0).length, [medicationTimeLines]);

  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    toggleDrawer(false);
  };

  const baseHeight = 300;
  const additionalHeightPerPoint = 6;
  const chartHeight = baseHeight + (filteredData.length * additionalHeightPerPoint);

  const minHeight = 400;
  const maxHeight = 628;
  useEffect(() => {
    const updateChartHeight = () => {
      const dataCount = filteredData.length;
      const calculatedHeight = baseHeight + dataCount * additionalHeightPerPoint;

      setBoxHeight(Math.min(Math.max(calculatedHeight, minHeight), maxHeight));
    };
    updateChartHeight();

    window.addEventListener('resize', updateChartHeight);

    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, [filteredData]);

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={handleDrawerClose}
      sx={{
        height: '100%',
        width: '75%',
        '& .MuiDrawer-paper': { width: '75%', boxSizing: 'border-box' },
      }}
    >
      <Box>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          p={2}
          pb={3}
          mt={1}
          mb={1}
          alignItems='center'
          justifyContent='space-between'
          height={10}
          sx={{ borderBottom: `1px solid ${Theme.custom.colors.backgroundColorSecondary}` }}
        >
          <Typography fontSize={20} fontWeight={700}>
            Medication History
          </Typography>
          <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
        </Box>
        <Box sx={{ width: '100%', marginTop: 2, marginBottom: 5, overflow: 'hidden' }}>
          <Box
            sx={{
              display: 'flex',
              borderWidth: 1,
              border: '1px solid',
              borderColor: Theme.custom.darkTextPrimary,
              borderRadius: '4px',
              width: 320,
              flexShrink: 0,
              marginLeft: 3,
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleSelectedTab}
              centered
              TabIndicatorProps={{ style: { display: 'none' } }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '& .MuiTab-root': {
                  flex: 1,
                  fontWeight: 500,
                  fontSize: '15px',
                  borderRight: '1px solid',
                  borderRightColor: Theme.custom.darkTextPrimary,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  color: Theme.custom.darkTextPrimary,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  height: '42px',
                  '&:last-of-type': {
                    borderRight: 'none',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#0288D11F',
                    color: Theme.custom.darkTextPrimary,
                  },
                },
              }}
            >
              <Tab label={`PSYCH (${psychCount})`} value={0} />
              <Tab label={`OTHER (${otherCount})`} value={1} />
              <Tab label={`BOTH (${bothCount})`} value={2} />
            </Tabs>
          </Box>
          <Box
            sx={{ marginLeft: 2,
              marginTop: 2,
              flex: 1,
              height: boxHeight,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div
                style={{
                  flex: 1,
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  height: boxHeight,
                  paddingTop: 55,
                }}
              >
                <ResponsiveContainer width='100%' height={chartHeight}>
                  <LineChart
                    data={filteredData}
                    margin={{ top: 15, right: 30, left: 45, bottom: 13 }}
                  >
                    <CartesianGrid strokeDasharray='0 0' />
                    <XAxis
                      dataKey='date'
                      type='number'
                      domain={[domainValue[0], domainValue[1]]}
                      tickFormatter={(unixTime) => `${formatTickLabel(unixTime, maxDate, minDate)}`}
                      dy={16}
                      allowDataOverflow
                      hide
                      tickSize={0}
                      ticks={ticks}
                    />
                    <YAxis
                      dataKey='name'
                      type='category'
                      padding={{ top: 0.5 }}
                      width={120}
                      tickMargin={10}
                      allowDataOverflow={false}
                      tickSize={0}
                      axisLine
                      tick={(
                        <CustomYAxisTick
                          medicationTimeLines={medicationTimeLines}
                          handleSelectedMedication={handleSelectedMedication}
                        />
)}
                    />
                    <Tooltip
                      content={(
                        <CustomTooltip
                          activeTooltipData={activeTooltipData}
                        />
)}
                      allowEscapeViewBox={{ x: true, y: true }}
                      cursor={false}
                    />
                    {[
                      ...new Set(filteredData.map((item) => item.name)),
                    ].map((medicationName) => {
                      const medication = medicationTimeLines.patientMedicationTimelines?.find(
                        (med) => med.medicationMetaName === medicationName,
                      );
                      const isPsychMedication = medication?.isPsychMedication;
                      return (
                        <Line
                          key={medicationName}
                          type='monotone'
                          dataKey='name'
                          style={{ zIndex: 0 }}
                          stroke={isPsychMedication ? '#33739D' : '#8E4C7D'}
                          strokeWidth={15}
                          dot={false}
                          connectNulls={false}
                          strokeLinecap='round'
                          data={filteredData.filter((point) => point.name === medicationName)}
                          activeDot={false}
                          isAnimationActive={false}
                          onMouseEnter={() => {
                            const hoveredDataPoint = filteredData.filter((point) => point.name
                         === medicationName);
                            if (hoveredDataPoint) {
                              setActiveTooltipData({
                                tooltipMedicationName: medicationName,
                                hoveredPoints: hoveredDataPoint,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            setActiveTooltipData({
                              tooltipMedicationName: null,
                              hoveredPoints: null,
                            });
                          }}
                        />
                      );
                    })}
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div style={{ flex: '0 0 auto', backgroundColor: 'white', zIndex: 10, paddingLeft: 120, marginTop: -20 }}>
                <ResponsiveContainer width='100%' height={50}>
                  <LineChart
                    data={filteredData}
                    margin={{ right: 30, left: 45, bottom: 20 }}
                  >
                    <XAxis
                      dataKey='date'
                      type='number'
                      domain={() => [domainValue[0], domainValue[1]]}
                      tickFormatter={(unixTime) => `${formatTickLabel(unixTime, maxDate, minDate)}`}
                      dy={16}
                      allowDataOverflow
                      ticks={ticks}
                      tickSize={0}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 5 }}>
            <Typography
              color={Theme.custom.colors.lightTextSecondary}
              fontSize={16}
              ml={2}
            >
              Records without dates:
              <Typography
                color={Theme.custom.colors.lightTextPrimary}
                display='inline-block'
                ml={1.5}
              >
                {getNoTimelineCount(
                  selectedTab,
                  noTimelinePsychCount,
                  noTimelineOtherCount,
                  noTimelineBothCount,
                )}
              </Typography>
            </Typography>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <Slider
                getAriaLabel={() => 'Minimum distance'}
                value={domainValue}
                onChange={handleSliderChange}
                valueLabelDisplay='on'
                valueLabelFormat={(v) => `${formatTickLabel(v, maxDate, minDate)}`}
                getAriaValueText={(v) => `${v}`}
                disableSwap
                min={ticks[0]}
                max={ticks[ticks.length - 1]}
                sx={{ width: 500 }}
              />
            </Box>
          </Box>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 12,
              alignItems: 'center',
              width: '100%',
              marginTop: 10,
            }}
          >
            {renderLegendContent(selectedTab)}
          </div>
        </Box>
      </Box>
    </Drawer>
  );
}

export default MedicationChartDrawer;
