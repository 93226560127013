import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Close } from '@mui/icons-material';
import Theme from 'theme';

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

function SearchBar({ onSearch }: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    onSearch(newValue);
  };
  const handleClear = () => {
    setSearchTerm('');
    onSearch('');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
        width: '100%',
      }}
    >
      <TextField
        variant='outlined'
        placeholder='Search by name'
        value={searchTerm}
        onChange={handleSearchChange}
        className='fs-mask'
        sx={{
          width: '50%',
          borderRadius: '44px',
          backgroundColor: Theme.custom.colors.white,
          '& .MuiOutlinedInput-root': {
            borderRadius: '44px',
            height: '44px',
          },
          alignSelf: 'center',
          height: '44px',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchTerm ? (
            <InputAdornment position='end'>
              <IconButton onClick={handleClear}>
                <Close style={{ borderRadius: '50%', fontSize: 20, padding: 4, backgroundColor: '#F2F2F2' }} />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </Box>
  );
}

export default SearchBar;
