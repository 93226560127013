import { SourceType } from 'pages/Dashboard/types';
import { Doctor } from 'pages/Dashboard/types/diagnosis.types';
import { GoogleDate } from 'utils/dateUtils';

export enum MedicationCategory {
  MEDICATION_CATEGORY_UNSPECIFIED = 'MEDICATION_CATEGORY_UNSPECIFIED',
  MEDICATION_CATEGORY_PSYCH = 'MEDICATION_CATEGORY_PSYCH',
  MEDICATION_CATEGORY_OTHER = 'MEDICATION_CATEGORY_OTHER',
}

export interface PatientMedication {
  id: string;
  medication: Medication;
  instructions: string;
  quantity: string;
  status: string;
  started: GoogleDate | null;
  stopped: GoogleDate | null;
  doctor: Doctor;
  category: MedicationCategory;
  indication: string;
  source: keyof typeof SourceType;
  refillCount: number;
  medicationMetaId:string
  onsetDate:GoogleDate | null;
  latestDate:GoogleDate | null;
}


export interface Medication {
  id: string;
  rxcui: string;
  brandName: string;
  displayName: string;
  fullName: string;
  fullGenericName: string;
  strength: string;
  rxtermsDoseForm: string;
  standardDisplayName: string;
  genericRxcui: string;
  rxNormDoseForm: string;
  suppress: string;
  synonym: string;
  route: string;
  refillCount?: number;

}

export interface MedicationAPIResponse {
  patientMedications: PatientMedication[];
}

export interface MedicationCountAPIResponse {
  summary: {
    patientId: string;
    summary: {
      name: string,
      count: number
    }[];
  }
}

export interface Refills {
  quantity: number;
  dosage: string;
  dosageUnit: string;
  dispensedOn:GoogleDate;
  orderedOn:GoogleDate;
  readableStartDate?:string | number;
  providerName?:string;
  dispensedDate?:number;
  dosageString?:string;
  provider:{
    firstName:string;
    lastName: string
  }
}

export interface RefillsApiResponse {
  patientMedicationRefills: {
    refills:Refills[],
    refillsWithoutDates:Refills[],
    isMultipleIngredient:boolean
  };
}

export interface TimeLines {
  year: number;
  month: number;
  day: number;
}


export interface Timeline {
  startDate: TimeLines;
  endDate: TimeLines;
}

export interface PatientMedicationTimeLines {
  medicationMetaName: string;
  medicationMetaId: string
  isPsychMedication: boolean;
  patientMedicationId: string;
  timelines: Timeline[];
}


export interface PatientMedications {
  patientId: string;
  patientMedicationTimelines: PatientMedicationTimeLines[];
}

export interface MedicationTimeLineChart {
  patientMedicationsTimelines: PatientMedications;
}
