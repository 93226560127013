import React, { ReactElement } from 'react';
import { SleepCategory, SleepCategoryMap } from 'pages/Dashboard/types/waypoints.types';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Theme from 'theme';
import { format } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const getArrowIcon = (up: boolean | undefined) => {
  if (up === true) {
    return ArrowDropUpIcon;
  }
  if (up === false) {
    return ArrowDropDownIcon;
  }
  return undefined;
};

export function convertDecimalToHoursMinutes(
  decimalNumber: number,
  key?: string,
  fontWeight = '400',
  keepActualColor = false,
): React.ReactElement | null {
  const number = typeof decimalNumber === 'string' ? parseFloat(decimalNumber) : decimalNumber;
  const hours = Math.floor(number);
  const minutes = Math.round((number - hours) * 60);
  const values = [];
  if (hours >= 1) {
    values.push({ key: 'h', value: minutes === 60 ? hours + 1 : hours });
  }
  if (minutes >= 1 && minutes < 60) {
    values.push({ key: 'min', value: minutes });
  }

  if (key === 'awake_time_sleep_occurence') {
    return (
      <Box display='flex' alignItems='baseline'>
        <Typography
          variant='body1'
          lineHeight='100%'
          fontWeight={fontWeight}
          fontSize={fontWeight === '700' ? 20 : 16}
        >
          {decimalNumber.toFixed(0)}
        </Typography>
        {fontWeight !== '400' && (
          <Typography
            variant={fontWeight === '700' ? 'body1' : 'body2'}
            lineHeight='100%'
            ml={0.5}
            {...(!keepActualColor ? { color: Theme.custom.colors.lightTextSecondary } : {})}
          >
            per night
          </Typography>
        )}
      </Box>
    );
  }

  if (key?.includes('sleep_avg')) {
    return (
      <Box display='flex' alignItems='baseline'>
        <Typography variant='body1' lineHeight='100%'>
          {format(decimalNumber * 1000, 'h:mm a')}
        </Typography>
      </Box>
    );
  }

  if (values.length === 0) {
    return null;
  }

  return (
    <Box display='flex'>
      {values.map((avg, index) => (
        <Typography
          key={avg.key}
          marginRight={index === 0 ? 1 : 0}
          display='flex'
          alignItems='baseline'
        >
          <Typography
            variant='body1'
            lineHeight='100%'
            fontWeight={fontWeight}
            fontSize={fontWeight === '700' ? 20 : 16}
          >
            {avg.value}
          </Typography>
          <Typography
            variant={fontWeight === '700' ? 'body1' : 'body2'}
            lineHeight='100%'
            {...(!keepActualColor ? { color: Theme.custom.colors.lightTextSecondary } : {})}
          >
            {avg.key}
          </Typography>
        </Typography>
      ))}
    </Box>
  );
}

interface AppleHealthSleepChartProps {
  timeFrame: string;
  averages: { id: SleepCategory; absoluteValue: number }[];
  previousPeriodAverages: { id: SleepCategory; absoluteValue: number }[];
  sourceId: string;
  sourceOptions: { label: string; value: string; isSelected: boolean }[];
  setSourceId: (sourceId: string) => void;
}

function AppleHealthSleepChartSummary({
  timeFrame,
  averages,
  previousPeriodAverages,
  sourceId,
  sourceOptions,
  setSourceId,
}: AppleHealthSleepChartProps) {
  const previousPeriodAverageMap = React.useMemo(
    () => previousPeriodAverages?.reduce?.((a, avg) => {
      if (avg?.id) {
        return {
          ...a,
          [avg.id]: {
            component: convertDecimalToHoursMinutes(avg.absoluteValue, avg.id, '400.0', false),
            value: avg.absoluteValue,
          },
        };
      }
      return a;
    }, {} as Record<SleepCategory, { component: ReactElement; value: number }>) ?? [],
    [previousPeriodAverages],
  );

  const averageMap = React.useMemo(
    () => averages?.reduce?.(
      (a, avg) => {
        if (avg?.id) {
          const showingOccurences = avg.id.includes('sleep_occurence');
          const currentValue = showingOccurences
            ? avg.absoluteValue.toFixed(0)
            : avg.absoluteValue;
          const previousValue = showingOccurences
            ? previousPeriodAverageMap[avg.id]?.value.toFixed(0)
            : previousPeriodAverageMap[avg.id]?.value;
          const prevValueString = convertDecimalToHoursMinutes(previousValue as number);
          const currentValueString = convertDecimalToHoursMinutes(currentValue as number);
          const isEqual = currentValueString === prevValueString;
          const isUp = currentValueString && prevValueString && currentValueString
           !== prevValueString
            ? avg.absoluteValue > previousPeriodAverageMap[avg.id]?.value
            : undefined;
          return {
            ...a,
            [avg.id]:
            {
              component:
               (fontWeight?: string) => convertDecimalToHoursMinutes(
                 avg.absoluteValue,
                 avg.id,
                 fontWeight,
               ),
              up: isUp,
              isEqual,
            },
          };
        }
        return a;
      },
        {} as Record<
          SleepCategory,
          {
            component: (fontWeight?: string) => ReactElement;
            up: true | false | undefined;
            isEqual: boolean;
          }
        >,
    ) ?? [],
    [averages, previousPeriodAverageMap],
  );

  const averageCategories = React.useMemo(
    () => Object.keys(SleepCategoryMap)?.reduce?.((a, key) => {
      if (!SleepCategoryMap[key as SleepCategory].isAsleepCategory) {
        a.splice(SleepCategoryMap[key as SleepCategory].index, 0, key);
      }
      return a;
    }, [] as string[]) ?? [],
    [],
  );

  const priorAvgText = React.useMemo(
    () => `prior ${timeFrame.replace(' ', '-').replace('s', '')} avg.`,
    [timeFrame],
  );

  const avgValues = React.useMemo(
    () => averageCategories.map((actualKey, index) => {
      const key = (actualKey as SleepCategory) === SleepCategory.AWAKE_TIME
        ? 'awake_time_sleep_occurence'
        : (actualKey as SleepCategory);
      const currentValue = averageMap?.[key as SleepCategory]?.component?.('700');
      const previousValue = previousPeriodAverageMap?.[key as SleepCategory]?.component;
      const currentColor = currentValue ? '' : Theme.custom.colors.lightTextSecondary;

      const ArrowIcon = getArrowIcon(averageMap?.[key as SleepCategory]?.up);
      const isEqual = averageMap?.[key as SleepCategory]?.isEqual;
      return (
        <Grid item key={key} mb={2} mt={3} width='33%'>
          <Box pl={index > 0 ? 1 : 0} pr={index < averageCategories.length ? 1 : 0} display='flex' flexDirection='column' justifyContent='flex-start' textAlign='left'>
            <Typography
              variant='body1'
              color={
                  SleepCategoryMap[actualKey as SleepCategory].averageLabelColor
                  || SleepCategoryMap[actualKey as SleepCategory].color
                }
              textAlign='left'
              fontWeight='700'
              fontSize='15px'
              sx={{ textWrap: 'nowrap' }}
            >
              {SleepCategoryMap[actualKey as SleepCategory].averageLabel}
            </Typography>
            <Typography
              color={currentColor}
              mb={2}
              lineHeight='100%'
              display='flex'
              alignItems='center'
              height={20}
              sx={{ textWrap: 'nowrap' }}
            >
              {currentValue ?? '-'}
              { isEqual ? null : averageMap?.[key as SleepCategory]?.up
                && ArrowIcon && (
                <ArrowIcon color='action' />
              )}
            </Typography>
            <Typography fontSize={12} color={Theme.custom.colors.lightTextSecondary} mb={0.5} sx={{ textWrap: 'nowrap' }}>
              {priorAvgText}
            </Typography>
            <Typography lineHeight='100%' sx={{ textWrap: 'nowrap' }}>
              {previousValue ?? '-'}
            </Typography>
          </Box>
        </Grid>
      );
    }),
    [averageCategories, averageMap, previousPeriodAverageMap, priorAvgText],
  );
  const handleChange = (event: SelectChangeEvent) => {
    setSourceId(event.target.value as string);
  };

  return (
    <Box width='100%' display='flex' flexDirection='column' alignItems='flex-start'>
      {sourceOptions.length > 1 && (
        <FormControl sx={{ width: '100%', mt: 3, mb: 3 }}>
          <InputLabel>Data source</InputLabel>
          <Select value={sourceId} label='Data source' onChange={handleChange} className='fs-mask'>
            {sourceOptions.map((s) => (
              <MenuItem key={s.value} value={s.value} className='fs-mask'>
                {s.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {sourceOptions.length === 1 && (
        <Typography color={Theme.custom.colors.lightTextSecondary} sx={{ mb: 3 }} className='fs-mask'>
          Source:
          {' '}
          {sourceOptions[0].label}
        </Typography>
      )}
      {averages?.length > 0 ? (
        <Box textAlign='left'>
          <Grid container>{avgValues}</Grid>
        </Box>
      ) : (
        <Box textAlign='center' width='100%'>
          <Typography>No data exists for this timeframe</Typography>
        </Box>
      )}
    </Box>
  );
}

export default AppleHealthSleepChartSummary;
