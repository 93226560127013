import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import { PatientGender } from 'pages/Dashboard/types';
import AddressSearch from 'pages/Authentication/components/AddressSearch';
import Input from 'components/Common/Input';
import { formattedDates } from 'utils/dateUtils';
import RadioInput, { RadioOption } from 'components/Common/RadioInput';
import { Address, ResultItem } from 'pages/Authentication/types/AddressSearchAutoComplete';
import { SMSConsent } from 'pages/Dashboard/components/AddPatientConsents';
import PhoneNumberInput from 'components/Common/PhoneNumberInput';
import { differenceInYears } from 'date-fns';
import MinorConsentForm from 'pages/Dashboard/components/MinorConsentForm';
import { Card, CardContent } from '@mui/material';

const genderOptions: RadioOption[] = [
  {
    label: 'Male',
    value: PatientGender.MALE,
  },
  {
    label: 'Female',
    value: PatientGender.FEMALE,
  },
];

export default function PatientDemographicForm() {
  const { control, setValue, watch } = useFormContext();
  const [defaultAddress, setDefaultAddress] = useState<ResultItem>({ label: '', id: 'defaultId' });
  const [autoAddressField, setAutoAddressField] = React.useState<ResultItem | null>(null);

  const dob = watch('dob');
  const isMinor = differenceInYears(new Date(), new Date(dob)) < 18;

  function onInputChange(value: string) {
    setDefaultAddress({ label: value, id: 'defaultId' });
    setValue('street', value);
  }

  function onAddressSelect(address: Address | null, value: ResultItem | null) {
    if (!address) {
      return;
    }

    setAutoAddressField(value);
    setValue('street', value?.label || '');
    setValue('state', address.state?.longName);
    setValue('city', address.city?.longName);
    setValue('zipCode', address.zipCode?.longName);
    setValue('streetAdditional', '');
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} className='fs-mask'>
                <Input
                  name='firstName'
                  control={control}
                  label='Legal first name'
                  required
                />
              </Grid>
              <Grid item md={6} xs={12} className='fs-mask'>
                <Input
                  name='lastName'
                  control={control}
                  label='Legal last name'
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6} className='fs-mask'>
                <Input
                  name='preferredName'
                  control={control}
                  label='Preferred name (optional)'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className='fs-mask'>
                <Input
                  name='dob'
                  control={control}
                  label='Date of birth'
                  required
                  textFieldProps={{
                    inputProps: { max: formattedDates.today, type: 'date' },
                    InputLabelProps: { shrink: true },
                  }}
                />
              </Grid>
              {
            isMinor && (
            <Grid item md={12}>
              <MinorConsentForm />
            </Grid>
            )
          }
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container className='fs-mask'>
              <RadioInput
                control={control}
                label='Sex assigned at birth'
                name='gender'
                options={genderOptions}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={4} className='fs-mask'>
                <PhoneNumberInput
                  name='mobile'
                  control={control}
                  label='Mobile phone number'
                  required
                />
              </Grid>
              <Grid item mt={2}>
                <SMSConsent />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6} className='fs-mask'>
                <Input
                  name='email'
                  control={control}
                  label='Email address (optional)'
                  textFieldProps={{
                    type: 'email',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={12} className='fs-mask'>
                <AddressSearch
                  inputLabel='Patient address'
                  addressValue={autoAddressField || defaultAddress}
                  onAddressSelect={onAddressSelect}
                  onInputChange={onInputChange}
                  isRequired
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={4} className='fs-mask'>
                <Input
                  name='streetAdditional'
                  control={control}
                  label='Ste, bldg, apt, etc.'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className='fs-mask'>
                <Input
                  name='city'
                  control={control}
                  label='City'
                  required
                />

              </Grid>
              <Grid item xs={12} md={2} className='fs-mask'>
                <Input
                  name='state'
                  control={control}
                  label='State'
                  required
                />
              </Grid>
              <Grid item xs={12} md={2} className='fs-mask'>
                <Input
                  name='zipCode'
                  control={control}
                  label='ZIP'
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Input
                  name='nextAppointmentDate'
                  control={control}
                  label='Date of next appointment'
                  required
                  textFieldProps={{
                    inputProps: { min: formattedDates.today, type: 'date' },
                    InputLabelProps: { shrink: true },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
