import { capitalize, Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface NameTextProps extends TypographyProps {
 userName?:{
  firstName?: string;
  lastName?: string
}
}
const splitStringToInitialAndOtherCharacters = (userName?:
  {
  firstName?:string,
  lastName?:string
}) => {
  const firstName = capitalize(userName?.firstName ?? '');
  const lastName = capitalize(userName?.lastName ?? '');
  const firstNameInitial = firstName?.[0] ?? '';
  const otherFirstNameCharacters = firstName?.slice?.(1) ?? '';
  const lastNameInitial = lastName?.[0] ?? '';
  const otherLastNameCharacters = lastName?.slice?.(1) ?? '';
  return { firstNameInitial, otherFirstNameCharacters, lastNameInitial, otherLastNameCharacters };
};
function NameText({ userName, ...rest }: NameTextProps) {
  const { firstNameInitial,
    otherFirstNameCharacters,
    lastNameInitial,
    otherLastNameCharacters } = splitStringToInitialAndOtherCharacters(userName);
  return (
    <Typography style={{ cursor: 'pointer', display: 'flex' }} {...rest}>
      {firstNameInitial}
      <Typography className='fs-mask' mr={1} {...rest}>
        {otherFirstNameCharacters}
      </Typography>
      {lastNameInitial}
      <Typography className='fs-mask' {...rest}>
        {otherLastNameCharacters}
      </Typography>
    </Typography>
  );
}

export default NameText;
