
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import { FetchContextProvider } from 'hooks/use-fetch';
import Auth0ProviderWithHistory from 'pages/Authentication/components/Auth0-provider-with-history';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter,
} from 'react-router-dom';
import Router from 'Router';
import Theme from 'theme';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Auth0ProviderWithHistory>
            <FetchContextProvider>
              <ThemeProvider theme={Theme}>
                <ErrorBoundary>
                  <CssBaseline enableColorScheme />
                  <Router />
                </ErrorBoundary>
              </ThemeProvider>
            </FetchContextProvider>
          </Auth0ProviderWithHistory>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
