import { Tabs } from 'pages/Rewards/types/tabs.types';


export const tabItemsMap = {
  [Tabs.REWARDS]: {
    label: 'REWARDS',
    key: Tabs.REWARDS,
  },
  [Tabs.WAYS_TO_EARN]: {
    label: 'WAYS TO EARN',
    key: Tabs.WAYS_TO_EARN,
  },
  [Tabs.TRANSACTION_HISTORY]: {
    label: 'TRANSACTION HISTORY',
    key: Tabs.TRANSACTION_HISTORY,
  },
};

export const tabItems = Object.values(tabItemsMap);

