import { Autocomplete, debounce, TextField, Typography } from '@mui/material';
import useGoogleMapsSDK from 'hooks/use-google-maps-sdk';
import {
  Address,
  Name,
  PLACE_DETAILS,
  ResultItem,
} from 'pages/Authentication/types/AddressSearchAutoComplete';
import * as React from 'react';

interface AddressSearchProp {
  onAddressSelect: (address: Address | null, value: ResultItem | null) => void;
  addressValue: ResultItem | null;
  inputLabel: string;
  onInputChange?: (value: string) => void;
  isRequired?: boolean;
}

const convertNameToPascalCase = ({ long_name: longName, short_name: shortName }: Name) => ({
  longName,
  shortName,
});

type GoogleAutoCompletePrediction = globalThis.google.maps.places.AutocompletePrediction;

export default function AddressSearch({
  onAddressSelect,
  addressValue,
  onInputChange,
  inputLabel,
  isRequired,
}: AddressSearchProp) {
  const [value, setValue] = React.useState<ResultItem | null>(null);
  const [oldValue, setOldValue] = React.useState<ResultItem | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const google = useGoogleMapsSDK();
  const [searchResults, setSearchResults] = React.useState<ResultItem[]>([]);

  React.useEffect(() => {
    setValue(addressValue);
  }, [addressValue]);

  const parsePlacesPredictionResults = React.useCallback(
    (results: GoogleAutoCompletePrediction[]): ResultItem[] => results.map((result) => ({
      id: result.place_id,
      label: result.description,
    })),
    [],
  );

  const getPlacesPredictions = React.useCallback(
    async (val): Promise<ResultItem[]> => {
      if (!google) {
        return [];
      }

      const googleAutocompleteService = new google.maps.places.AutocompleteService();
      return new Promise((resolve, reject) => {
        googleAutocompleteService.getPlacePredictions(
          { input: val },
          (
            results: globalThis.google.maps.places.AutocompletePrediction[],
            status: globalThis.google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(parsePlacesPredictionResults(results));
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            reject([]);
          },
        );
      });
    },
    [google, parsePlacesPredictionResults],
  );

  const getSearchResults = React.useMemo(
    () => debounce(async (input) => {
      try {
        setLoading(true);
        const place = await getPlacesPredictions(input);
        setSearchResults(place);
      }
      catch (_err) {
        setSearchResults([]);
      }
      finally {
        setLoading(false);
      }
    }, 50),
    [getPlacesPredictions],
  );

  const getPlaceDetails = React.useCallback(
    async (placeId: string): Promise<Address> => {
      if (!google) {
        return { formattedAddress: '' };
      }

      const map = new google.maps.Map(document.createElement('div'));
      const googlePlacesService = new google.maps.places.PlacesService(map);
      return new Promise((resolve, reject) => {
        googlePlacesService.getDetails(
          { placeId },
          (
            place: globalThis.google.maps.places.PlaceResult,
            status: globalThis.google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(parsePlaceDetails(place));
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(null);
          },
        );
      });
    },
    [google],
  );

  const parsePlaceDetails = (placeDetails: globalThis.google.maps.places.PlaceResult): Address => {
    const place: Address = {
      formattedAddress: placeDetails[PLACE_DETAILS.FORMATTED_ADDRESS],
    };

    if (placeDetails.address_components) {
      placeDetails.address_components.forEach((item) => {
        const pascalCaseNameObject = convertNameToPascalCase(item);
        item.types.forEach((type) => {
          switch (type) {
            case PLACE_DETAILS.STREET_NUMBER:
              place.streetNumber = pascalCaseNameObject;
              break;

            case PLACE_DETAILS.COUNTRY:
              place.country = item.short_name;
              break;

            case PLACE_DETAILS.STREET:
              place.street = pascalCaseNameObject;
              break;

            case PLACE_DETAILS.CITY:
              place.city = pascalCaseNameObject;
              break;

            case PLACE_DETAILS.STATE:
              place.state = pascalCaseNameObject;
              break;

            case PLACE_DETAILS.ZIP_CODE:
              place.zipCode = pascalCaseNameObject;
              break;

            default:
              break;
          }
        });
      });
    }

    return place;
  };

  React.useEffect(() => {
    if (!inputValue || !google) {
      return;
    }
    getSearchResults(inputValue);
  }, [getSearchResults, google, inputValue]);

  React.useEffect(() => {
    if (!value) {
      return;
    }

    if (value === oldValue) {
      return;
    }

    const handleResultSelect = async (item: ResultItem) => {
      try {
        const address = await getPlaceDetails(item.id || '');
        onAddressSelect(address, value);
        setOldValue(value);
      }
      catch (_err) {
        onAddressSelect(null, null);
      }
    };

    handleResultSelect(value);
  }, [getPlaceDetails, oldValue, onAddressSelect, value]);

  return (
    <Autocomplete
      popupIcon={null}
      id='combo-box-demo'
      options={searchResults}
      autoComplete
      includeInputInList
      loading={loading}
      noOptionsText='No locations'
      filterSelectedOptions
      value={value}
      className='fs-mask'
      renderOption={(props, option) => <Typography {...props} className='fs-mask' sx={{ p: 1, cursor: 'pointer' }}>{option.label}</Typography>}
      onChange={(event, _val) => {
        if (!_val) {
          setSearchResults([]);
        }
        setValue(_val);
      }}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onInputChange?.(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={inputLabel} required={isRequired} className='fs-mask' />}
    />
  );
}
