import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import CardHeader from '@mui/material/CardHeader';
import { Box, IconButton, Popover, Switch, Typography } from '@mui/material';
import Theme from 'theme';

export interface PatientInfoFormHeaderProps {
  isDemographicForm: boolean;
  setIsDemographicForm: (value: boolean) => void;
}

export default function PatientInfoFormHeader({
  isDemographicForm,
  setIsDemographicForm,
}: PatientInfoFormHeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popoverData = () => (
    <Box sx={{ p: 2, maxWidth: { md: 300 } }}>
      <Typography variant='h6' mb={1}>Settings</Typography>
      <Box display='flex'>
        <Box>
          <Switch
            checked={isDemographicForm}
            onChange={() => {
              setIsDemographicForm(!isDemographicForm);
              closePopover();
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
        <Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='body2'>Enter demographic info myself</Typography>
            <Typography variant='body2' color='GrayText'>
              Faster medical record access, but requires accurate data entry
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <CardHeader
      action={(
        <>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <SettingsIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {popoverData()}
          </Popover>
        </>
      )}
      avatar={isDemographicForm ? <PersonIcon sx={{ color: '#0000008A' }} /> : <PeopleIcon sx={{ color: '#0000008A' }} />}
      title={<Typography variant='h6' color={Theme.custom.colors.lightTextPrimary}>{isDemographicForm ? 'Patient' : 'Patients'}</Typography>}
      sx={{ p: 0, pb: 2 }}
    />
  );
}
