import { Box, Typography, capitalize } from '@mui/material';
import SectionHeader from 'components/Common/SectionHeader';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import EditWaypointsButton from 'pages/Dashboard/EditWaypointsButton';
import WaypointsTrackingTable from 'pages/Dashboard/components/WaypointsTrackingTable';
import useWaypointSuggestions from 'pages/Dashboard/hooks/useWaypointSuggestions';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { GroupedWaypoints, WaypointTracking } from 'pages/Dashboard/types/waypoints.types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Logger } from 'utils/logger';

interface WaypointTrackingSectionProps {
  patient: PatientObj | null;
  timeFrame: string;
  currentWaypoints: WaypointTracking[];
  previousWaypoints: WaypointTracking[];
  allWaypoints: GroupedWaypoints;
}

export default function WaypointTrackingSection({
  patient,
  timeFrame,
  currentWaypoints,
  previousWaypoints,
  allWaypoints,
}: WaypointTrackingSectionProps) {
  const { id: patientId } = useParams();
  const { providerId, isNewVersionEnabled } = useHttp();

  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);

  const onError = (message: string) => {
    Logger.error(message);
    setToastProps({
      message,
      open: true,
      type: ToastType.error,
    });
  };

  const {
    standardWaypoints,
    patientWaypoints,
    waypointSuggestions,
    revokeWaypointSuggestions,
    sendWaypointSuggestions,
    isFetchingPatientWaypoints,
    isFetchingWaypointSuggestions,
    refetchWaypointSuggestions,
    refetchPatientWaypoints,
  } = useWaypointSuggestions({ providerId, patientId, onError });

  const patientName = patient?.name?.firstName && patient?.name?.firstName?.length > 0
    ? capitalize(patient?.name?.firstName ?? '')
    : 'the patient';

  async function refetch() {
    await refetchWaypointSuggestions();
    await refetchPatientWaypoints();
  }

  const activityText = isNewVersionEnabled ? 'Activity' : 'Waypoint';
  const activitiesText = isNewVersionEnabled ? 'Activities' : 'Waypoints';
  const hasPatientWaypoints = patientWaypoints.length > 0;
  const hasSuggestions = waypointSuggestions.length > 0;

  return (
    <>
      <Box mt={4}>
        <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
          <SectionHeader title={`${activityText} tracking`} />
          {patient && (
            <EditWaypointsButton
              patient={patient}
              standardWaypoints={standardWaypoints}
              patientWaypoints={patientWaypoints}
              waypointSuggestions={waypointSuggestions}
              handleDeleteConfirmation={revokeWaypointSuggestions}
              handleSendSuggestions={sendWaypointSuggestions}
              refetch={refetch}
              isLoading={isFetchingPatientWaypoints || isFetchingWaypointSuggestions}
            />
          )}
        </Box>
        {hasPatientWaypoints ? (
          <Typography variant='body2' color='gray' mt={1} className='fs-mask'>
            {`
            For each ${activityText.toLowerCase()}, ${patientName} subjectively selects the occurrence rate using a “less/typical/more” scale during logging timeframe.
            The responses below represent ${`${patientName}’s`} most common selection for each ${activityText.toLowerCase()}.
            Use the dropdown at the top of the page to adjust timeframes.
          `}
          </Typography>
        ) : (
          <>
            {!hasSuggestions ? (
              <Typography variant='body2' color='gray' mt={1}>
                {`
            ${patientName} has not selected any ${activitiesText.toLowerCase()} to track.
          `}
              </Typography>
            ) : null}
            <Typography variant='body2' color='gray' mt={1}>
              {activitiesText}
              {' '}
              are behaviors that the patient can track to help discover correlations to
              their mood.
            </Typography>
          </>
        )}
      </Box>
      <Box mt={4}>
        {hasPatientWaypoints || waypointSuggestions.length ? (
          <WaypointsTrackingTable
            timeFrame={timeFrame}
            currentWaypoints={currentWaypoints}
            previousWaypoints={previousWaypoints}
            allWaypoints={allWaypoints}
            waypointSuggestions={waypointSuggestions}
            patient={patient}
          />
        ) : null}
      </Box>
      {toastProps ? <Toast {...toastProps} onClose={() => setToastProps(null)} /> : null}
    </>
  );
}
