import React from 'react';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { TableCell, TableRow, Typography } from '@mui/material';
import ResolveInvite from 'pages/Dashboard/components/ResolveInvite';
import { formatPhoneNumber } from 'utils/phoneUtils';
import { capitalize } from 'utils/appUtils';

interface PatientComponentProps {
    patient: PatientObj;
}


function ErrorPatientComponent(props: PatientComponentProps) {
  const { patient } = props;
  const isNamePending = !patient?.name?.firstName;

  // Convert phone number to string or display fallback if missing
  const formattedPhoneNumber = formatPhoneNumber(patient.phoneNumber);
  const phoneNumberString = `Pending (${String(
    formattedPhoneNumber || 'No Number Available',
  )})`;
  const nameString = `${capitalize(patient.name?.firstName ?? '')} ${capitalize(patient.name?.lastName ?? '')}`;
  return (
    <TableRow
      sx={{
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <TableCell
        key={patient.id}
        scope='patient'
        className='fs-mask'
        sx={{
          color: 'grey',
          fontWeight: patient.hasRead ? 600 : 400,
          width: 400,
        }}
      >
        {isNamePending ? phoneNumberString : nameString}
      </TableCell>
      <TableCell sx={{ padding: '4px 8px' }}>
        <Typography sx={{ textAlign: 'center' }}>{patient.dateAdded ? new Date(patient.dateAdded).toLocaleDateString('en-US') : ''}</Typography>
      </TableCell>

      <TableCell
        color='grey'
        sx={{
          ml: -2,
          pl: 0,
        }}
      >
        <ResolveInvite patient={patient} btnText='RESOLVE ERROR' />
      </TableCell>
    </TableRow>
  );
}


export default ErrorPatientComponent;
