import * as React from 'react';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useHttp } from 'hooks/use-fetch';
import { useMutation } from 'react-query';
import { createWaypoint } from 'pages/Dashboard/services/waypoints.services';
import { CreateWaypointPayload, Waypoints } from 'pages/Dashboard/types';
import { Add } from '@mui/icons-material';
import { capitalize } from 'utils/appUtils';

interface ComponentProps {
  customWaypoints: Waypoints[];
  setCustomWayPoints: React.Dispatch<React.SetStateAction<Waypoints[]>>;
  checkHandler: (key: Waypoints, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CustomWayPointsComponent({
  customWaypoints,
  setCustomWayPoints,
  checkHandler,
}: ComponentProps) {
  const theme = useTheme();
  const [addCustom, setAddCustom] = React.useState(false);
  const [customVal, setCustomVal] = React.useState('');
  const [addWaypoint, setAddWaypoint] = React.useState<Waypoints>({} as Waypoints);
  const { http, providerId, isNewVersionEnabled } = useHttp();

  React.useEffect(() => {
    if (addWaypoint?.displayName) {
      setAddCustom((currentAddCustom) => !currentAddCustom);
      setCustomWayPoints((currentCustomWaypoints) => [...currentCustomWaypoints, addWaypoint]);
      setCustomVal('');
    }
  }, [addWaypoint, setCustomWayPoints]);

  const { mutate: addNewWaypoint, isLoading } = useMutation({
    mutationFn: (payload: CreateWaypointPayload) => createWaypoint(
      http.post,
      providerId,
      { ...payload },
    ),
    onSuccess: ({ waypoint }) => {
      setAddWaypoint(waypoint);
    },
  });

  const setButtonClicked = async () => {
    const mockId = Math.floor(Math.random() * 100000).toString();
    addNewWaypoint({
      waypoint: {
        id: mockId,
        name: customVal,
        category: 'Custom',
        displayName: customVal,
        creatorType: '',
      },
    });
  };

  const activityText = isNewVersionEnabled ? 'activity' : 'waypoint';

  return (
    <Stack spacing={1}>
      <FormControl>
        {customWaypoints.length ? <FormLabel>Custom</FormLabel> : ''}
        {customWaypoints.map(({ id, name, displayName, category }) => (
          <FormControlLabel
            key={id}
            value={name}
            control={
              <Checkbox onChange={(e) => checkHandler({ id, name, category }, e)} name={name} />
            }
            label={displayName}
          />
        ))}
      </FormControl>

      {!addCustom ? (
        <Button
          onClick={() => setAddCustom(!addCustom)}
          sx={{ width: { xs: '100%', sm: 'fit-content' } }}
          variant='outlined'
          startIcon={<Add />}
        >
          ADD CUSTOM
          {' '}
          {activityText.toUpperCase()}
        </Button>
      ) : (
        <Stack spacing={1}>
          <Typography>
            Custom
            {activityText}
          </Typography>
          <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap={theme.spacing(2)}>
            <TextField
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              value={customVal}
              onChange={(e) => setCustomVal(e.target.value)}
              label={`${capitalize(activityText)} name`}
            />
            <Box display='flex' gap={theme.spacing(2)}>
              <Button variant='contained' onClick={setButtonClicked} disabled={isLoading}>
                ADD
              </Button>
              <Button variant='outlined' onClick={() => setAddCustom(!addCustom)}>
                CANCEL
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
