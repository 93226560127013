import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import {
  PatientContext,
  patientContextInitialState,
  patientReducer,
} from 'pages/Dashboard/AddPatientContext';
import AddPatientForm from 'pages/Dashboard/AddPatientForm';
import AddPatientSuccess from 'pages/Dashboard/components/AddPatientSuccess';
import { addPatient } from 'pages/Dashboard/services/patient.services';
import { PatientContextAction, PatientContextValues, PatientFormData } from 'pages/Dashboard/types';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { setAddPatientPayload } from 'utils/service';
import Result from 'pages/Dashboard/types/mixpanel.types';
import fetchPracticeList from 'pages/Dashboard/services/addpatient.services';
import Spinner from 'components/Common/Spinner';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export default function AddPatient() {
  const { http, providerId } = useHttp();
  const [state, dispatch] = React.useReducer(patientReducer, patientContextInitialState);
  const [addPatientTime, setAddPatientTime] = React.useState<string>(new Date().toISOString());
  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);

  const { data: practicesList, isFetching: practiceListFetching } = useQuery('practices-list', {
    queryFn: fetchPracticeList(http.get, providerId),
  });

  const createPatient = useMutation({
    mutationFn: (payload: PatientFormData) => addPatient(http.post, providerId, payload),
    onSettled: (data, error) => {
      const eventPayload = {
        'datetime requested': addPatientTime,
        'datetime completed': new Date().toISOString(),
        result: data?.success ? Result.SUCCESS : Result.FAIL,
      };

      trackMixpanelEvent(MixpanelEventName.HEALTH_RECORDS_REQUESTED, eventPayload);
    },
    onError: (error) => {
      setToastProps({
        message: (error as Error).message,
        open: true,
        type: ToastType.error,
      });
    },
  });

  const value: PatientContextValues = React.useMemo(
    () => ({
      addingPatient: Boolean(createPatient?.isLoading),
      dispatch,
      state,
      practicesList: practicesList || null,
    }),
    [createPatient?.isLoading, state, practicesList],
  );


  async function onAddPatient(e: React.FormEvent) {
    e.preventDefault();

    setAddPatientTime(new Date().toISOString());
    const data = setAddPatientPayload(state.formData, state.waypoints);
    createPatient.mutate(data);
  }

  if (createPatient?.isSuccess) {
    return (
      <AddPatientSuccess
        onAddAnotherPatientClick={() => {
          dispatch({ type: PatientContextAction.RESET_FORM_DATA });
          createPatient.reset();
        }}
      />
    );
  }

  if (practiceListFetching) {
    return <Spinner />;
  }

  return (
    <PatientContext.Provider value={value}>
      {toastProps && (
      <Toast
        {...toastProps}
        onClose={() => setToastProps(null)}
      />
      )}
      <form onSubmit={onAddPatient}>
        <AddPatientForm />
      </form>
    </PatientContext.Provider>
  );
}
