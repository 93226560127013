import { useTheme } from '@emotion/react';
import * as React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const sentenceCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

interface CustomLabelProps {
  cx?: number;
  cy?: number;
  label?: string;
}

function CustomLabel(props: CustomLabelProps): JSX.Element {
  const { cx, cy = 0, label } = props;

  const lines = label ? label.split(' ') : [];

  const lineHeight = 20;
  const totalHeight = lines.length * lineHeight;

  const verticalOffset = -(totalHeight / 4);

  return (
    <text
      x={cx}
      y={cy}
      fill='black'
      textAnchor='middle'
      dominantBaseline='middle'
      fontSize={16}
      fontWeight={400}
    >
      {label?.split(' ').map((line: string, i: number) => (
        <tspan
          x={cx}
          dy={i === 0 ? verticalOffset : lineHeight}
          textAnchor='middle'
        >
          {i === 0 ? sentenceCase(line) : line}
        </tspan>
      ))}
    </text>
  );
}

type GaugeData = { value: number };

interface GaugeChartProps {
  color: string;
  data: GaugeData[];
  label: string;
}

export default function GaugeChart({ color, data, label }: GaugeChartProps): JSX.Element {
  const theme = useTheme();

  return (
    <ResponsiveContainer width='100%' height={180}>
      <PieChart margin={{ bottom: 0, top: 0, right: 0, left: 0 }}>
        <Pie
          cy='65%'
          startAngle={225}
          endAngle={-45}
          innerRadius='70%'
          data={data}
          dataKey='value'
          labelLine={false}
          blendStroke
          isAnimationActive={false}
          label={<CustomLabel label={label} />}
        >
          <Cell fill={color} />
          <Cell fill={theme.palette.mode === 'dark' ? 'grey' : '#d2d2d2'} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
