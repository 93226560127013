import { ListPracticeProvidersAPIResponse } from 'pages/SuperAdmin/types';
import { ListPracticesAPIResponse } from 'types/Entitites';
import { practiceUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchPracticeList = (method: HttpType['get'], providerId: string, queryParams?: string) => function async(): Promise<ListPracticesAPIResponse> {
  return method(practiceUrls.listPractices.apiUrls(providerId, queryParams).list);
};

export const fetchPlatformPracticeList = (method: HttpType['get'], queryParams?: string) => function async(): Promise<ListPracticesAPIResponse> {
  return method(practiceUrls.listPlatformPractices.apiUrls(queryParams).list);
};

export const fetchPracticeProviderList = (method: HttpType['get'], practiceId: string, queryParams?: string) => function async(): Promise<ListPracticeProvidersAPIResponse> {
  return method(practiceUrls.listPracticeProviders.apiUrls(practiceId, queryParams).list);
};

export default fetchPracticeList;
