import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useHttp } from 'hooks/use-fetch';
import usePatient from 'pages/Dashboard/AddPatientContext';
import CustomWayPointsComponent from 'pages/Dashboard/CustomWayPointsComponent';
import StandardWaypoints from 'pages/Dashboard/StandardWaypoints';
import { PatientContextAction, Waypoints } from 'pages/Dashboard/types';
import * as React from 'react';
import { capitalize } from 'utils/appUtils';

export default function AddPatientWayPoints() {
  const theme = useTheme();
  const { isNewVersionEnabled } = useHttp();
  const [customWaypoints, setCustomWayPoints] = React.useState<Waypoints[]>([]);
  const { state, dispatch } = usePatient();
  const { waypoints } = state;

  function checkHandler(selectedWaypoint: Waypoints, e: React.ChangeEvent<HTMLInputElement>) {
    let updatedWayPoints: Waypoints[] = [];

    const existingIndex = waypoints.findIndex((i) => i.id === selectedWaypoint.id);
    if (existingIndex !== -1) {
      waypoints.splice(existingIndex, 1);
      updatedWayPoints = waypoints;
    }
    else {
      updatedWayPoints = [...waypoints, selectedWaypoint];
    }

    dispatch({
      type: PatientContextAction.SET_WAYPOINTS,
      payload: { waypoints: updatedWayPoints },
    });
  }

  const activityText = isNewVersionEnabled ? 'activities' : 'waypoints';

  return (
    <Stack spacing={2}>
      <Box display='flex' gap={theme.spacing(1)}>
        <PhoneIphoneIcon />
        <Typography>
          Patient app
          {activityText}
        </Typography>
        <Tooltip title={`${capitalize(activityText)} are behaviors that the patient can track to help discover correlations to their mood and condition.We recommend selecting 4 - 8 ${activityText}.`}>
          <InfoIcon color='primary' />
        </Tooltip>
      </Box>
      <Typography fontStyle='italic'>
        At least 3
        {' '}
        {activityText}
        {' '}
        are required. Between 4 - 8 are recommended.
      </Typography>
      <StandardWaypoints checkHandler={checkHandler} />
      <CustomWayPointsComponent
        customWaypoints={customWaypoints}
        setCustomWayPoints={setCustomWayPoints}
        checkHandler={checkHandler}
      />
      {waypoints?.length > 8 ? (
        <Alert severity='info'>
          Note: Patients may have a difficult time initially tracking this many
          {' '}
          {activityText}
          .
          Additional
          {' '}
          {activityText}
          {' '}
          may be added later.
        </Alert>
      ) : null}
    </Stack>
  );
}
