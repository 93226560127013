import React from 'react';
import { Box, Checkbox, FormControlLabel, TextField, debounce } from '@mui/material';
import Autocomplete, { Option } from 'pages/ScalesManifest/components/Autocomplete';
import { format } from 'date-fns';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { useMutation } from 'react-query';
import { UserMetadata, UserMetadataFlags } from 'pages/Dashboard/types';
import { postUserMetadata } from 'pages/Dashboard/services/provider.services';
import { useHttp } from 'hooks/use-fetch';

export type TFilters = {
  providerName: Option;
  patientName: Option;
  scale: Option;
  startDate: string;
  endDate: string;
  showLatestAttempts: boolean;
};

interface IFiltersProps {
  filters: TFilters;
  setFilters: React.Dispatch<React.SetStateAction<TFilters>>;
  providerNameOptions: Option[];
  patientNameOptions: Option[];
  scaleOptions: Option[];
  isFetching: boolean;
}

function Filters({
  filters,
  setFilters,
  providerNameOptions,
  patientNameOptions,
  scaleOptions,
  isFetching,
}: IFiltersProps) {
  const { providerId, http } = useHttp();
  const { providerName, patientName, scale, startDate } = filters;

  const setUserMetadataMutation = useMutation({
    mutationFn: (payload: UserMetadata) => postUserMetadata(http.post, { ...payload }),
  });

  const updateShowAllScalesForBillingAdmin = React.useCallback((val: boolean) => {
    setUserMetadataMutation.mutate({
      userId: providerId,
      flag: {
        name: UserMetadataFlags.ShowAllScalesForBillingAdmin,
        value: val.toString(),
      },
    });
  }, [providerId, setUserMetadataMutation]);

  const setFilterChange = React.useCallback((
    key: keyof TFilters,
    value: Option | Date | string | boolean,
  ) => {
    trackMixpanelEvent(MixpanelEventName.FILTERS_APPLIED, {
      filter: key,
    });
    if (key === 'showLatestAttempts') {
      updateShowAllScalesForBillingAdmin(!value as boolean);
    }
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  }, [setFilters, updateShowAllScalesForBillingAdmin]);

  const setFiltersValue = (key: keyof TFilters) => (value: Option) => {
    setFilterChange(key, value);
  };

  const setDateFiltersValue = (key: keyof TFilters) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value;
    if (value) {
      const year = parseInt(value.split('-')[0], 10);
      const isValidYear = year > 1900 && year < 2100;
      if (isValidYear) {
        setFilterChange(key, value);
      }
    }
    else {
      setFilterChange(key, '');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterChange('showLatestAttempts', event.target.checked);
  };

  const formattedTodaysDate = React.useMemo(
    () => format(new Date(), 'yyyy-MM-dd'),
    [],
  );

  return (
    <Box paddingY={2}>
      <Box display='flex' flexDirection='row' alignItems='flex-start' mb={2} gap={2}>
        <Autocomplete
          value={providerName}
          label='Provider name'
          setValue={setFiltersValue('providerName')}
          options={providerNameOptions}
          loading={isFetching}
          disabled={isFetching}
          testId='provider-name-autocomplete'
        />
        <Autocomplete
          value={patientName}
          label='Patient name'
          setValue={setFiltersValue('patientName')}
          options={patientNameOptions}
          loading={isFetching}
          disabled={isFetching}
          className='fs-mask'
        />
        <Autocomplete
          value={scale}
          label='Scale'
          setValue={setFiltersValue('scale')}
          options={scaleOptions}
          loading={isFetching}
          disabled={isFetching}
        />
        <TextField
          label='Start Date'
          placeholder='Start Date'
          type='date'
          size='small'
          InputLabelProps={{ shrink: true }}
          onChange={debounce(setDateFiltersValue('startDate'), 500)}
          inputProps={{ max: formattedTodaysDate }}
          fullWidth
          disabled={isFetching}
        />
        <TextField
          label='End Date'
          type='date'
          size='small'
          InputLabelProps={{ shrink: true }}
          onChange={debounce(setDateFiltersValue('endDate'), 500)}
          inputProps={{ min: startDate || undefined, max: formattedTodaysDate }}
          fullWidth
          disabled={isFetching}
        />
      </Box>
      <FormControlLabel
        label='Show latest scales only'
        control={(
          <Checkbox
            checked={Boolean(filters.showLatestAttempts)}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )}
      />
    </Box>
  );
}

export default Filters;
