import * as React from 'react';
import RewardsButton from 'pages/Rewards/components/RewardsButton';
import RewardsDrawer from 'pages/Rewards/components/RewardsDrawer';
import useRewards from 'pages/Rewards/hooks/use-rewards';
import RewardsContext, { ActiveComponent, TComponent } from 'pages/Rewards/context/RewardsContext';
import { Tabs } from 'pages/Rewards/types/tabs.types';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export default function Rewards() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeComponent, setActiveComponent] = React.useState<ActiveComponent<unknown>>({
    component: TComponent.DEFAULT,
  });
  const [activeTab, setActiveTab] = React.useState(Tabs.REWARDS);

  const rewardsState = useRewards({ isOpen });
  const { rewardPoints, isEnabled } = rewardsState;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (open) {
      trackMixpanelEvent(MixpanelEventName.POINT_BALANCE_ICON_CLICKED);
    }
    else {
      trackMixpanelEvent(MixpanelEventName.HEADLAMP_GIVES_BACK_PANEL_CLOSED);
    }
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  const contextValue = React.useMemo(
    () => ({ ...rewardsState, activeComponent, activeTab, setActiveTab, setActiveComponent }),
    [rewardsState, activeComponent, activeTab],
  );

  React.useEffect(() => {
    if (!isOpen) {
      setActiveComponent({ component: TComponent.DEFAULT });
      setActiveTab(Tabs.REWARDS);
    }
  }, [isOpen]);

  return (
    <RewardsContext.Provider value={contextValue}>
      {isEnabled ? (
        <RewardsButton clickHandler={toggleDrawer(true)} rewardPoints={rewardPoints} />
      ) : null}
      <RewardsDrawer isOpen={isOpen} onClose={toggleDrawer(false)} />
    </RewardsContext.Provider>
  );
}
