import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

export enum NavTreeIds {
  HOME = 'home',
  ADD_PATIENT = 'add-patient',
  REPORTS = 'reports',
}

export enum UserRole {
  CHIEF_ADMIN = 'chief-admin',
  BILLING_ADMIN = 'billingadmin',
  PROVIDER = 'provider',
  SUPER_ADMIN = 'superadmin',
  STAFF = 'staff',
}

export type NavItemModel = {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string; };
  spaUrl: string;
  id: NavTreeIds;
  roles: UserRole[];
}

export type NavTreeModel = NavItemModel[];
