
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import Dropdown, { DropdownOption } from 'components/Common/Dropdown';
import { useHttp } from 'hooks/use-fetch';
import { useQuery } from 'react-query';
import fetchPracticeList from 'pages/Dashboard/services/addpatient.services';
import { useFormContext } from 'react-hook-form';
import { providers } from 'utils/apiUrls';
import { fetchPracticeProvidersList, fetchProvidersAssociationsList } from 'pages/Dashboard/services/provider.services';
import { PracticeProvidersListResponse, ProviderDesgination } from 'pages/Dashboard/types/providers.types';
import Theme from 'theme';


export default function PracticeInfoForm() {
  const { http, providerId } = useHttp();
  const { control, setValue, watch } = useFormContext();
  const [practiceOptions, setPracticeOptions] = useState<DropdownOption[]>([]);
  const [practiceProviderOptions, setPracticeProviderOptions] = React.useState<DropdownOption[]>(
    [],
  );

  useQuery('practices-list', {
    queryFn: fetchPracticeList(http.get, providerId),
    onSuccess: (data) => {
      if (data.practices?.length === 1) {
        setValue('practiceId', data.practices[0].id);
      }

      const newPracticeOptions: DropdownOption[] = data.practices.map(
        (practice) => ({ label: practice.name, value: practice.id }),
      );

      setPracticeOptions(newPracticeOptions);
    },
  });

  const { isLoading: associationProvidersListLoading, data: associationProviderIds } = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'practise-info-form'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
      select: (data) => data.providers.map((provider) => provider.id),
    },
  );

  const { isFetching: providersLoading } = useQuery(
    [`${providers.practiceProvidersList.queryUrl}`, watch('practiceId')],
    {
      queryFn: fetchPracticeProvidersList(http.get, watch('practiceId') || ''),
      enabled: !associationProvidersListLoading && !!watch('practiceId'),
      onSuccess: (practiceProviders: PracticeProvidersListResponse) => {
        const newPracticeProviderOptions: DropdownOption[] = [];
        [...practiceProviders.providers]
          .sort((a, b) => b.lastName.localeCompare(a.lastName))
          .forEach((provider) => {
            const conditionToShowProvider = provider.designation
             !== ProviderDesgination.PROVIDER_DESIGNATION_OFFICE_STAFF
            && (provider.id === providerId || associationProviderIds?.includes(provider.id));
            if (conditionToShowProvider) {
              newPracticeProviderOptions.push({ label: `${provider.firstName} ${provider.lastName}`, value: provider.id });
            }
          });

        setPracticeProviderOptions(newPracticeProviderOptions);

        if (newPracticeProviderOptions.length === 1) {
          setValue('providerId', newPracticeProviderOptions[0].value);
        }
      },
    },
  );

  const practiceName = React.useMemo(() => {
    const selectedPractice = practiceOptions.find((practice) => practice.value === watch('practiceId'));
    return selectedPractice?.label;
  }, [practiceOptions, watch]);

  const providerName = React.useMemo(() => {
    const selectedProvider = practiceProviderOptions.find((provider) => provider.value === watch('providerId'));
    return selectedProvider?.label;
  }, [practiceProviderOptions, watch]);

  return (
    <Box sx={{ width: 0.9 }}>
      <CardHeader
        avatar={<LocationCityIcon sx={{ color: '#0000008A' }} />}
        title={(
          <Typography variant='h6' color={Theme.custom.colors.lightTextPrimary}>
            {practiceProviderOptions.length > 1 ? 'Practice / clinician' : `${practiceName}${providerName ? ` / ${providerName}` : ''}`}
          </Typography>
        )}
        sx={{ p: 0, pb: practiceProviderOptions.length > 1 ? 2 : 0 }}
      />
      {practiceProviderOptions.length > 1 && (
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Dropdown control={control} label='Practice' name='practiceId' options={practiceOptions} required />
              </Grid>
              <Grid item md={6}>
                <Dropdown
                  control={control}
                  label='Select Provider'
                  name='providerId'
                  options={practiceProviderOptions}
                  required
                  disabled={!watch('practiceId') || providersLoading || associationProvidersListLoading}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
