import React from 'react';
import { Typography, Box } from '@mui/material';
import { Doctor, ProviderFullDetails } from 'pages/Dashboard/types/diagnosis.types';
import DoctorDesignationText from 'pages/Dashboard/components/DoctorDesignationText';
import Theme from 'theme';

// Define the type for each data item
interface DataItem {
  [key: string]: string | number | ProviderFullDetails | Doctor | null;
}

// Define the props for TimelineTable
interface TimelineTableProps<T> {
  data: T[];
  headers: string[];
  keys: string[];
  widths :number[];
  keyData: {
    headerText: string;
    key: string
    width: number
  }[]
}


function TimelineTable({ data, headers, keys, widths, keyData }: TimelineTableProps<unknown>) {
  const finalKeys = keyData.filter((key) => data.some((item, index) => ((item as DataItem)[key.key] !== 'unknown' && (item as DataItem)[key.key] !== undefined && (item as DataItem)[key.key] !== '' && (item as DataItem)[key.key] !== null)));
  const dataWithValues = data.filter((item, index) => {
    if (finalKeys.every((key) => (item as DataItem)[key.key] === 'unknown' || (item as DataItem)[key.key] === undefined || (item as DataItem)[key.key] === '' || (item as DataItem)[key.key] === null)) {
      return false;
    }
    return true;
  });
  const noOfDataPointsWithNoValue = data.length - dataWithValues.length;
  const extraRecordString = `${noOfDataPointsWithNoValue} other record${noOfDataPointsWithNoValue > 1 ? 's' : ''} with no additional information`;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, width: 'auto' }}>
      {finalKeys?.length > 0 ? (
        <Box sx={{ textAlign: 'left', display: 'flex', paddingBottom: '8px', marginLeft: '24px' }}>
          {finalKeys.map((finalKey, index) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 500, width: finalKeys[index].width ?? 0, fontSize: 16 }} key={finalKey?.headerText}>
              {finalKey.headerText}
            </Typography>
          ))}
        </Box>
      ) : null}

      {dataWithValues.map((item, index) => (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '35px', width: 'auto' }}>
            <Box sx={{
              width: '12px',
              height: '12px',
              backgroundColor: Theme.custom.colors.lightTextSecondary,
              borderRadius: '50%',
              mr: '12px',
            }}
            />
            {finalKeys.map((key, keyIndex) => (key.key === 'doctor' ? <DoctorDesignationText doctor={(item as DataItem).doctor as ProviderFullDetails} /> : (
              <Typography sx={{ textAlign: 'left', width: finalKeys[keyIndex].width, fontSize: 16 }} key={key.key} className='fs-mask'>
                {(item as DataItem)[key.key]}
              </Typography>
            )))}
          </Box>
          {index !== data.length - 1 ? (
            <Box width='12px' display='flex' justifyContent='center'>
              <Box sx={{ height: '20px', width: '2px', bgcolor: Theme.custom.colors.lightTextSecondary }} />
            </Box>
          ) : null}
        </Box>
      ))}
      {noOfDataPointsWithNoValue > 0 ? (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '35px', width: 'auto', mt: -2 }}>
            <Box sx={{
              width: '12px',
              height: '12px',
              backgroundColor: Theme.custom.colors.lightTextSecondary,
              borderRadius: '50%',
              mr: '12px',
            }}
            />
            <Typography sx={{ textAlign: 'left', fontSize: 16 }}>
              {extraRecordString}
            </Typography>

          </Box>

        </Box>
      ) : null}
    </Box>

  );
}

export default TimelineTable;
