import { Box, Card, CardContent, CardHeader, Grid, Tab, Tabs, Typography } from '@mui/material';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { MedicationCategory, PatientMedication } from 'pages/Dashboard/types/medication.types';
import React from 'react';
import theme from 'theme';
import { googleDateToDateString } from 'utils/dateUtils';

export enum TabLabels {
  PSYCH = 'PSYCH',
  OTHER = 'OTHER',
}

export interface MedicationCardProps extends Partial<ShowMoreDrawerChildProps> {
  medication?: PatientMedication[];
  showTabs: boolean;
  drawerTabIndex?: number;
  isCurrentRegime: boolean;
  onShowMoreMedication?: (d: PatientMedication[], idx?: number) => void;
}

type TabPanelsProps = {
  children: React.ReactNode;
  medication: PatientMedication[];
};

const getMedicationName = ({ medication }: PatientMedication) => {
  if (medication.standardDisplayName) {
    return medication.standardDisplayName;
  }
  const name = medication.fullGenericName.length > 0 && medication.fullGenericName !== ''
    ? medication.fullGenericName
    : medication.fullName;
  const brandName = medication.brandName.length > 0 ? `(${medication.brandName})` : '';
  return `${name} ${brandName}`;
};

function MedicationTabPanels({ children, medication }: TabPanelsProps): JSX.Element {
  const unknownDateMedications = medication.filter((med) => !med.started);

  return !medication.length ? (
    <NoDataForNow />
  ) : (
    <>
      {medication.map((currentMedication) => {
        if (!currentMedication.started) {
          return null;
        }
        return (
          <React.Fragment key={`current-regimen-${currentMedication.id}`}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} className='fs-mask'>
              {googleDateToDateString(currentMedication.started)}
            </Typography>
            <Box mb={2}>
              <Typography>{getMedicationName(currentMedication)}</Typography>
              <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary}>{`${currentMedication?.medication?.strength}`}</Typography>
            </Box>
          </React.Fragment>
        );
      })}
      {unknownDateMedications.length ? (
        <>
          <Typography mb={2} variant='body1' color={theme.custom.colors.lightTextSecondary}>
            Unknown dates
          </Typography>
          {unknownDateMedications.map((meds) => (
            <Box mb={2}>
              <Typography>{getMedicationName(meds)}</Typography>
              <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary}>{`${meds?.medication?.strength}`}</Typography>
            </Box>
          ))}
        </>
      ) : null}
      {children}
    </>
  );
}

export default function MedicationCard({
  medication = [],
  showTabs,
  drawerTabIndex,
  closeDrawer,
  drawerItem,
  drawerMode = false,
  isCurrentRegime,
  onShowMoreMedication = () => null,
}: MedicationCardProps): JSX.Element {
  const [value, setValue] = React.useState(0);

  const datedMedications = medication.filter((meds) => meds.started);
  const nonDatedMedications = medication
    .filter((meds) => !meds.started)
    .sort((a, b) => getMedicationName(a).localeCompare(getMedicationName(b)));

  const sortedMedications = [...datedMedications, ...nonDatedMedications];
  const meds = sliceForShowMore<PatientMedication>(sortedMedications, !drawerMode);

  const psychMedication = sortedMedications.filter(
    (med) => med.category === MedicationCategory.MEDICATION_CATEGORY_PSYCH,
  );
  const otherMedication = sortedMedications.filter(
    (med) => med.category !== MedicationCategory.MEDICATION_CATEGORY_PSYCH,
  );

  const psychMeds = sliceForShowMore(psychMedication, !drawerMode);
  const otherMeds = sliceForShowMore(otherMedication, !drawerMode);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (drawerMode) {
      setValue(drawerTabIndex || 0);
    }
  }, [drawerTabIndex, drawerMode]);

  const renderTabs = () => (showTabs ? (
    <>
      <Box mb={2}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={TabLabels.PSYCH} />
          <Tab label={TabLabels.OTHER} />
        </Tabs>
      </Box>
      {value === 0 ? (
        <MedicationTabPanels medication={psychMeds}>
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={() => onShowMoreMedication(medication, value)}
            items={psychMedication}
          />
        </MedicationTabPanels>
      ) : null}
      {value === 1 ? (
        <MedicationTabPanels medication={otherMeds}>
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={() => onShowMoreMedication(medication, value)}
            items={otherMedication}
          />
        </MedicationTabPanels>
      ) : null}
    </>
  ) : (
    <Box>
      <MedicationTabPanels medication={meds}>
        <ShowMoreButton
          isDrawer={drawerMode}
          onShowMore={() => onShowMoreMedication(medication)}
          items={medication}
        />
      </MedicationTabPanels>
    </Box>
  ));

  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='current-regimen-medication'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={React.createElement(HealthRecordCategories.medication.value.icon, { fontSize: 'large' })}
            title='Medication'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && (
          <ShowMoreDrawerTitle
            closeDrawer={closeDrawer as VoidFunction}
            drawerItem={drawerItem as string}
          />
        )}
        <CardContent>{!medication.length ? <NoDataForNow /> : <>{renderTabs()}</>}</CardContent>
      </Card>
    </Grid>
  );
}
