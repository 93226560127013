import { ResultItem } from 'pages/Authentication/types/AddressSearchAutoComplete';

export enum Source {
  HealthGorilla = 'HEALTH GORILLA',
  Cerner = 'CERNER',
}

export type Connection = {
  id: string;
  source: string;
  type: string;
  credentials: ConnectionCredentials[];
};

export enum ConnectionType {
  R4 = 'R4',
  STU3 = 'STU3',
}

export enum CredentialType {
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  Sub = 'sub',
  Issuer = 'issuer',
  Audience = 'audience',
}

export type ConnectionCredentials = {
  name: CredentialType;
  value: string;
};

type name = {
  firstName: string;
  lastName: string;
};

export type User = {
  id: string,
  name: string,
  email: string,
}

export enum Designation {
  PROVIDER_DESIGNATION_PSYCHIATRIST = 'PROVIDER_DESIGNATION_PSYCHIATRIST',
  PROVIDER_DESIGNATION_NURSE_PRACTITIONER = 'PROVIDER_DESIGNATION_NURSE_PRACTITIONER',
  PROVIDER_DESIGNATION_COUNSELOR = 'PROVIDER_DESIGNATION_COUNSELOR',
  PROVIDER_DESIGNATION_THERAPIST = 'PROVIDER_DESIGNATION_THERAPIST',
  PROVIDER_DESIGNATION_CLINICAL_SOCIAL_WORKER = 'PROVIDER_DESIGNATION_CLINICAL_SOCIAL_WORKER',
  PROVIDER_DESIGNATION_OFFICE_STAFF = 'PROVIDER_DESIGNATION_OFFICE_STAFF',
  PROVIDER_DESIGNATION_BILLING_ADMIN = 'PROVIDER_DESIGNATION_BILLING_ADMIN',
}

export enum EProfessions {
  PROVIDER_DESIGNATION_PSYCHIATRIST = 'Psychiatrist',
  PROVIDER_DESIGNATION_NURSE_PRACTITIONER = 'Nurse Practitioner',
  PROVIDER_DESIGNATION_COUNSELOR = 'Counselor',
  PROVIDER_DESIGNATION_THERAPIST = 'Therapist',
  PROVIDER_DESIGNATION_CLINICAL_SOCIAL_WORKER = 'Clinical Social Worker',
  PROVIDER_DESIGNATION_OFFICE_STAFF = 'Office Staff',
  PROVIDER_DESIGNATION_BILLING_ADMIN = 'Billing Admin',
}

export interface Provider {
  id: string;
  name: Partial<name>;
  email: string;
  designation: EProfessions;
  npiNumber: number;
}

export type PracticeAddress = {
  street: string;
  streetAdditional: string;
  city: string;
  state: string;
  zip: string;
  fieldValue: ResultItem | null;
  country?: string;
};

export interface Practice {
  id: string;
  name: string;
  address: Partial<PracticeAddress>;
  connections?: Connection[];
  action?: number;
  timeAudit?: {
    createdAt: string;
  }
}

export type CreateProviderRequestPayload = {
  request:
  Partial<Provider> & { practices: Partial<Practice>[] }
};

export type ListPracticesAPIResponse = {
  practices: Practice[];
};
