import { Box, Grid, Link, Typography } from '@mui/material';
import AppleHealthIcon from 'components/Common/AppleHealthIcon';
import { useHttp } from 'hooks/use-fetch';
import WaypointTrackingSection from 'pages/Dashboard/WaypointTrackingSection';
import AppleHealthActivityChart from 'pages/Dashboard/components/AppleHealthActivityChart';
import AppleHealthActivitySummaryChart, {
  DataKey,
} from 'pages/Dashboard/components/AppleHealthActivitySummaryChart';
import AppleHealthChartsWrapper from 'pages/Dashboard/components/AppleHealthChartsWrapper';
import AppleHealthSleepChart from 'pages/Dashboard/components/AppleHealthSleepChart';
import AppleHealthSleepDialog from 'pages/Dashboard/components/AppleHealthSleepDialog';
import LoggedMoods from 'pages/Dashboard/components/LoggedMoods';
import MoodCardV1 from 'pages/Dashboard/components/MoodCard';
import MoodCardV2 from 'pages/Dashboard/components/MoodCardV2';
import { PatientCtx } from 'pages/Dashboard/constants';
import {
  fetchGamesTracking,
  fetchMostLoggedMetrics,
  fetchSomaticScore,
  fetchWaypointSources,
  fetchWaypointTracking,
} from 'pages/Dashboard/services/tracking.services';
import { fetchPatientWaypointList } from 'pages/Dashboard/services/waypoints.services';
import {
  Mood,
  MoodOptions,
  MoodTrackingAverageResponse,
  MoodTrackingListResponse,
  MoodV2,
} from 'pages/Dashboard/types/moods.types';
import {
  ListWaypointDetails,
  MoodFactorDomainsResponse,
  MostLoggedMetricsResponse,
  SomaticScoreResponse,
  StroopTimeSeriesAPIResponse,
  WaypointTracking,
  WaypointTrackingAPIResponse,
  WaypointType,
} from 'pages/Dashboard/types/waypoints.types';
import {
  TimeFrameOptions,
  getChartDateRanges,
  getFilteredWaypointsData,
  getMoodOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import { gamesUrls, trackingUrls, wayPointsUrls } from 'utils/apiUrls';
import { MixpanelEventName } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/utilMethods';

const stroopInfoText = 'The Stroop test is a neuropsychological test that measures how well a person can focus on one thing while ignoring others.';
const somaticScoreText = 'The Somatic score reflects how well the patient is doing with things like sleep, daylight exposure, and exercise — areas they can control to improve their overall mental well-being. The score is calculated using Apple Health data.';

enum MostLoggedMetrics {
  MOOD = 'MOOD',
  SITUATIONS = 'SITUATIONS',
  DOMAINS = 'DOMAINS',
}

const barSizeMap = {
  [`${TimeFrameOptions.LAST_7_DAYS}-4`]: 34,
  [`${TimeFrameOptions.LAST_30_DAYS}-4`]: 8,
  [`${TimeFrameOptions.LAST_3_MONTHS}-4`]: 67,
  [`${TimeFrameOptions.LAST_6_MONTHS}-4`]: 38,
  [`${TimeFrameOptions.LAST_7_DAYS}-6`]: 53,
  [`${TimeFrameOptions.LAST_30_DAYS}-6`]: 13,
  [`${TimeFrameOptions.LAST_3_MONTHS}-6`]: 106,
  [`${TimeFrameOptions.LAST_6_MONTHS}-6`]: 61,
};

type Waypoint = {
  id: string;
  title?: string;
  subTitle?: string;
};

interface PatientTrackingProps {
  timeFrame: string;
  onChangeTimeFrame: (v: string) => void;
  currentMoodData: MoodTrackingAverageResponse;
  previousMoodData: MoodTrackingAverageResponse;
  currentWaypointData: WaypointTrackingAPIResponse;
  previousWaypointData: WaypointTrackingAPIResponse;
  moodChartHappiness: MoodTrackingListResponse;
  moodChartAnxiousness: MoodTrackingListResponse;
  moodChartMotivation: MoodTrackingListResponse;
  isTrackingLoading: boolean;
  isMoodLoading: boolean;
}

export default function PatientTracking({
  timeFrame: selectedTimeFrame,
  onChangeTimeFrame,
  currentMoodData,
  previousMoodData,
  currentWaypointData,
  previousWaypointData,
  moodChartHappiness,
  moodChartAnxiousness,
  moodChartMotivation,
  isTrackingLoading,
  isMoodLoading,
}: PatientTrackingProps) {
  const patient = React.useContext(PatientCtx);
  const { http, providerId, isNewVersionEnabled } = useHttp();
  const { id: patientId } = useParams();

  const [sourceId, setSourceId] = React.useState<string>('');
  const [moodId, setMoodId] = React.useState('');
  const [dataMap, setDataMap] = React.useState<Record<string, boolean>>({});
  const [isDrawerOpen, setIsDrawerOpen] = React.useState('');

  const [isSixMonthDataFetched, setIsSixMonthDataFetched] = React.useState(false);

  const timeFrame = React.useMemo(
    () => (selectedTimeFrame === TimeFrameOptions.LAST_30_DAYS && !isSixMonthDataFetched
      ? TimeFrameOptions.LAST_6_MONTHS
      : selectedTimeFrame),
    [selectedTimeFrame, isSixMonthDataFetched],
  );

  const closeDrawer = React.useCallback(() => {
    setIsDrawerOpen('');
  }, []);

  const openDrawer = React.useCallback(() => {
    trackMixpanelEvent(
      MixpanelEventName.AH_SLEEP_CHART_VIEW_DETAILS_CLICKED,
      {
        'patient id': patientId ?? '',
      },
      true,
    );
    setIsDrawerOpen('show');
  }, [patientId]);

  const onChangeSource = React.useCallback(
    (source: string) => {
      trackMixpanelEvent(
        MixpanelEventName.AH_SLEEP_CHART_SOURCE_CHANGED,
        {
          'patient id': patientId ?? '',
        },
        true,
      );
      setSourceId(source);
    },
    [patientId],
  );

  const fetchPatientWaypointsResult = useQuery(
    [`${wayPointsUrls.listPatientWaypoints.queryUrl}`, patientId],
    {
      queryFn: fetchPatientWaypointList(http.get),
      enabled: !!providerId && !!patientId,
    },
  );

  const waypointIndexes = React.useMemo(() => ({} as Record<string, number>), []);
  const appleHealthWaypointMap = React.useMemo(() => ({} as Record<string, string>), []);
  const appleHealthWaypoints = React.useMemo(
    () => fetchPatientWaypointsResult?.data?.waypoints?.reduce((acc, w, i) => {
      if (w?.waypoint?.type === WaypointType.WAYPOINT_TYPE_APPLE_HEALTH) {
        appleHealthWaypointMap[w?.waypoint?.name] = w?.waypoint?.id;
        if (w?.waypoint?.name !== 'sleepAppleHealth') {
          acc.push({
            id: w?.waypoint?.id,
            title: w?.waypoint?.displayName,
            subTitle: w?.waypoint?.description,
          });
        }
      }

      return acc;
    }, [] as Waypoint[]) || [],
    [appleHealthWaypointMap, fetchPatientWaypointsResult?.data?.waypoints],
  );

  const fetchPatientWaypointSourcesResult = useQuery(
    [`${trackingUrls.waypointSources.queryUrl}`, providerId, patientId],
    {
      queryFn: fetchWaypointSources(http.get),
      enabled: !!providerId && !!patientId,
    },
  );

  const fetchMostLoggedMoodsResult = useQuery(
    [
      `${trackingUrls.mostLoggedMetrics.queryUrl}`,
      providerId,
      patientId,
      selectedTimeFrame,
      MostLoggedMetrics.MOOD,
    ],
    {
      queryFn: fetchMostLoggedMetrics(http.get),
      enabled: !!providerId && !!patientId && isNewVersionEnabled,
    },
  );

  const fetchMostLoggedSituationResult = useQuery(
    [
      `${trackingUrls.mostLoggedMetrics.queryUrl}`,
      providerId,
      patientId,
      selectedTimeFrame,
      moodId,
      'mood',
    ],
    {
      queryFn: fetchMostLoggedMetrics(http.get),
      enabled: !!providerId && !!patientId && isNewVersionEnabled,
    },
  );

  const mostLoggedMetrics = React.useMemo(
    () => ({
      mood: (fetchMostLoggedMoodsResult?.data as MostLoggedMetricsResponse)?.percents || [],
      factors: (fetchMostLoggedSituationResult?.data as MoodFactorDomainsResponse)
        ?.moodFactorDomains || [],
      moodsLoading: fetchMostLoggedMoodsResult?.isLoading,
      factorsLoading: fetchMostLoggedSituationResult?.isLoading,
    }),
    [
      fetchMostLoggedMoodsResult?.data,
      fetchMostLoggedMoodsResult?.isLoading,
      fetchMostLoggedSituationResult?.isLoading,
      fetchMostLoggedSituationResult?.data,
    ],
  );

  const sourceOptions = React.useMemo(
    () => fetchPatientWaypointSourcesResult?.data?.sources?.map((s) => {
      if (s.isSelected) {
        onChangeSource(s.sourceId);
      }
      return {
        label: s.sourceName,
        value: s.sourceId,
        isSelected: s.isSelected,
      };
    }) ?? [],
    [fetchPatientWaypointSourcesResult?.data?.sources, onChangeSource],
  );

  const queries = React.useMemo(
    () => [
      ...appleHealthWaypoints.map((w, i) => {
        waypointIndexes[w?.id] = i;
        return {
          queryKey: [`${trackingUrls.waypoint.queryUrl}`, providerId, patientId, w.id, timeFrame],
          queryFn: fetchWaypointTracking(http.get),
          enabled: !!providerId && !!patientId,
        };
      }),
      {
        queryKey: [`${gamesUrls.stroopTimeSeries.queryUrl}`, providerId, patientId, timeFrame],
        queryFn: fetchGamesTracking(http.get),
        enabled: !!providerId && !!patientId,
      },
    ],
    [appleHealthWaypoints, providerId, patientId, timeFrame, http.get, waypointIndexes],
  );

  const fetchPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}-${sourceId}-${timeFrame}`,
      providerId,
      patientId,
      appleHealthWaypointMap.sleepAppleHealth,
      timeFrame,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get, true),
      enabled:
        !!providerId && !!patientId && !!sourceId && !!appleHealthWaypointMap.sleepAppleHealth,
    },
  );

  const fetchSomaticScoreResult = useQuery(
    [
      `${trackingUrls.somaticScore.queryUrl}-${timeFrame}`,
      providerId,
      patientId,
      timeFrame,
    ],
    {
      queryFn: fetchSomaticScore(http.get),
      enabled:
        !!providerId && !!patientId && isNewVersionEnabled,
    },
  );

  const queriesResults = useQueries(queries);

  React.useEffect(() => {
    const waypointsFetched = fetchPatientWaypointsResult.isFetched;
    const sourcesLoaded = fetchPatientWaypointSourcesResult.isFetched;
    const ahWaypointsFetched = queriesResults.every((q) => q.isFetched);
    const sleepLoaded = fetchPatientWaypointTrackingResult.isFetched || !sourceId;
    const moodsDetails = !isNewVersionEnabled || fetchMostLoggedMoodsResult.isFetched;

    if (
      !isSixMonthDataFetched
      && timeFrame === TimeFrameOptions.LAST_6_MONTHS
      && waypointsFetched
      && sourcesLoaded
      && ahWaypointsFetched
      && sleepLoaded
      && moodsDetails
    ) {
      setIsSixMonthDataFetched(true);
    }
  }, [
    fetchPatientWaypointSourcesResult.isFetched,
    fetchPatientWaypointTrackingResult.isFetched,
    fetchPatientWaypointsResult.isFetched,
    isSixMonthDataFetched,
    queriesResults,
    sourceId,
    timeFrame,
    fetchMostLoggedMoodsResult,
    isNewVersionEnabled,
  ]);

  const appleHealthSleepData = React.useMemo(() => {
    const data = (
      fetchPatientWaypointTrackingResult?.data as ListWaypointDetails
    )?.categoryAvgData || [];
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);

  const appleHealthSleepPreviousPeriodAvgData = React.useMemo(() => {
    const data = (fetchPatientWaypointTrackingResult?.data as ListWaypointDetails)
      ?.previousIntervalCategoryAvgData || [];
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);

  const isAppleHealthSleepDataLoading = React.useMemo(
    () => fetchPatientWaypointTrackingResult?.isFetching,
    [fetchPatientWaypointTrackingResult?.isFetching],
  );

  const appleHealthActivity = React.useMemo(
    () => queriesResults[waypointIndexes[appleHealthWaypointMap.activityAppleHealth]]
      ?.data as WaypointTrackingAPIResponse,
    [appleHealthWaypointMap.activityAppleHealth, queriesResults, waypointIndexes],
  );

  const appleHealthActivityData = React.useMemo(
    () => appleHealthActivity?.waypoints || [],
    [appleHealthActivity?.waypoints],
  );

  const appleHealthActivityAverageData = React.useMemo(
    () => appleHealthActivity?.averages || [],
    [appleHealthActivity?.averages],
  );

  const appleHealthActivityPreviousPeriodAverageData = React.useMemo(
    () => appleHealthActivity?.previousIntervalAverages || [],
    [appleHealthActivity?.previousIntervalAverages],
  );

  const isAppleHealthActivityDataLoading = React.useMemo(
    () => queriesResults[waypointIndexes[appleHealthWaypointMap.activityAppleHealth]]?.isFetching,
    [appleHealthWaypointMap.activityAppleHealth, queriesResults, waypointIndexes],
  );

  const hasStandHours = React.useMemo(
    () => appleHealthActivityData?.some((w: WaypointTracking) => w?.metadata?.some((m) => m?.key === 'appleStandHours' && parseFloat(m?.value) > -1)),
    [appleHealthActivityData],
  );

  const appleHealthActivityAvgData = React.useMemo(() => {
    const data = appleHealthActivity || {};
    return {
      value: data?.absoluteAvgValue,
      unit: data?.absoluteValueUnit,
    };
  }, [appleHealthActivity]);

  const appleHealthActivityGoals = React.useMemo(
    () => appleHealthActivity?.goals || [],
    [appleHealthActivity?.goals],
  );

  const appleHealthDaylightExposure = React.useMemo(
    () => queriesResults[waypointIndexes[appleHealthWaypointMap.daylightExposure]]
      ?.data as WaypointTrackingAPIResponse,
    [appleHealthWaypointMap.daylightExposure, queriesResults, waypointIndexes],
  );

  const appleHealthDaylightExposureData = React.useMemo(
    () => appleHealthDaylightExposure?.waypoints || [],
    [appleHealthDaylightExposure?.waypoints],
  );

  const isAppleHealthDaylightExposureDataLoading = React.useMemo(
    () => queriesResults[waypointIndexes[appleHealthWaypointMap.daylightExposure]]?.isFetching,
    [appleHealthWaypointMap.daylightExposure, queriesResults, waypointIndexes],
  );

  const appleHealthDaylightExposureAvgData = React.useMemo(() => {
    const data = appleHealthDaylightExposure || {};
    return {
      value: data?.absoluteAvgValue,
      unit: data?.absoluteValueUnit,
    };
  }, [appleHealthDaylightExposure]);

  const appleHealthDaylightExposurePreviousPeriodAvgData = React.useMemo(() => {
    const data = appleHealthDaylightExposure?.previousIntervalAverages || [];
    return {
      value: data[0]?.value,
      unit: data[0]?.unit,
    };
  }, [appleHealthDaylightExposure]);

  const stroopTimeSeriesData = React.useMemo(
    () => (queriesResults[queries.length - 1]?.data as StroopTimeSeriesAPIResponse)?.data || [],
    [queriesResults, queries.length],
  );

  const stroopTimeSeriesAvgData = React.useMemo(
    () => (queriesResults[
      queries.length - 1
    ]?.data as StroopTimeSeriesAPIResponse)?.absoluteAvgValue
      || 0,
    [queriesResults, queries.length],
  );

  const somaticScoreTimeSeriesData = React.useMemo(() => {
    const data = (fetchSomaticScoreResult?.data as SomaticScoreResponse)?.data || [];
    return data;
  }, [fetchSomaticScoreResult?.data]);

  const somaticScoreTimeSeriesAvgData = React.useMemo(() => {
    const data = (fetchSomaticScoreResult?.data as SomaticScoreResponse)?.absoluteAvgValue || 0;
    return data;
  }, [fetchSomaticScoreResult?.data]);

  React.useEffect(() => {
    if (stroopTimeSeriesData.length > 0 && timeFrame === TimeFrameOptions.LAST_6_MONTHS) {
      setDataMap((prev) => ({ ...prev, stroop: true }));
    }
  }, [stroopTimeSeriesData.length, timeFrame]);

  const hasMostLoggedMetrics = React.useMemo(
    () => (mostLoggedMetrics.mood.length > 0
      || mostLoggedMetrics.factors.length > 0)
      && !(mostLoggedMetrics.factorsLoading || mostLoggedMetrics.moodsLoading),
    [mostLoggedMetrics.factors.length,
      mostLoggedMetrics.factorsLoading,
      mostLoggedMetrics.mood.length,
      mostLoggedMetrics.moodsLoading],
  );

  React.useEffect(() => {
    if (hasMostLoggedMetrics && timeFrame === TimeFrameOptions.LAST_6_MONTHS) {
      setDataMap((prev) => ({ ...prev, mostLoggedMetrics: true }));
    }
  }, [hasMostLoggedMetrics, stroopTimeSeriesData.length, timeFrame]);

  const isStroopTimeSeriesDataLoading = React.useMemo(
    () => queriesResults[queries.length - 1]?.isFetching,
    [queriesResults, queries.length],
  );

  const isSomaticScoresDataLoading = React.useMemo(
    () => fetchSomaticScoreResult?.isFetching,
    [fetchSomaticScoreResult?.isFetching],
  );

  const numberOfCards = React.useMemo(() => {
    let count = 0;
    const dataAvailable = {} as Record<string, boolean>;

    if (sourceId) {
      count += 1;
    }

    if (appleHealthActivityData.length > 0) {
      dataAvailable.activity = true;
    }
    count += appleHealthActivityData.length > 0 || dataMap.activity ? 1 : 0;

    if (appleHealthDaylightExposureData.length > 0) {
      dataAvailable.daylight = true;
    }
    count += appleHealthDaylightExposureData.length > 0 || dataMap.daylight ? 1 : 0;

    if (timeFrame === TimeFrameOptions.LAST_6_MONTHS) {
      setDataMap((prev) => ({
        ...prev,
        ...dataAvailable,
      }));
    }
    return count;
  }, [
    appleHealthActivityData.length,
    appleHealthDaylightExposureData.length,
    dataMap.activity,
    dataMap.daylight,
    sourceId,
    timeFrame,
  ]);

  const barSize = React.useMemo(() => {
    const gridSize = numberOfCards === 3 ? 4 : 6;
    const updatedBarSize = barSizeMap[`${timeFrame as TimeFrameOptions}-${gridSize}`];

    return updatedBarSize;
  }, [numberOfCards, timeFrame]);

  const getChartData = React.useCallback((mood: Mood | MoodV2) => {
    if (mood === Mood.MOOD_HAPPINESS || mood === MoodV2.MOOD_PLEASANTNESS) {
      return moodChartHappiness;
    }
    else if (mood === Mood.MOOD_MOTIVATION || mood === MoodV2.MOOD_ENERGY) {
      return moodChartMotivation;
    }
    return moodChartAnxiousness;
  }, [moodChartHappiness, moodChartMotivation, moodChartAnxiousness]);

  const groupedAllWaypoints = getFilteredWaypointsData(
    currentWaypointData.waypoints,
    previousWaypointData.waypoints,
  );

  const moodOptions: MoodOptions[] = React.useMemo(
    () => getMoodOptions(currentMoodData.moods, previousMoodData.moods, isNewVersionEnabled),
    [currentMoodData.moods, isNewVersionEnabled, previousMoodData.moods],
  );

  const yAxisProps = getChartDateRanges(timeFrame as TimeFrameOptions) as {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };

  const MoodCard = isNewVersionEnabled ? MoodCardV2 : MoodCardV1;

  const gridSize = React.useMemo(() => {
    if (isNewVersionEnabled) { return (hasMostLoggedMetrics || dataMap.mostLoggedMetrics) ? 4 : 6; }
    return 4;
  }, [dataMap.mostLoggedMetrics, hasMostLoggedMetrics, isNewVersionEnabled]);

  const onSelectMood = (selectedMoodId: string) => {
    setMoodId(selectedMoodId);
  };

  return (
    <Box>
      <Grid container spacing={2} mb={3}>
        {moodOptions.map((option) => (
          <MoodCard
            timeFrame={selectedTimeFrame}
            cardData={option}
            chartData={getChartData(option.mood)?.moods || []}
            noData={getChartData(option.mood)?.moods?.length === 0}
            isMoodLoading={isMoodLoading}
            gridSize={gridSize}
          />
        ))}
        {(hasMostLoggedMetrics || dataMap.mostLoggedMetrics) && isNewVersionEnabled && (
          <LoggedMoods
            timeFrame={timeFrame}
            data={mostLoggedMetrics}
            noData={!hasMostLoggedMetrics}
            onChangeTimeFrame={onChangeTimeFrame}
            onSelectMood={onSelectMood}
          />
        )}
      </Grid>
      {numberOfCards === 0 && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          bgcolor='#0288D120'
          border='1px dashed'
          borderColor={theme.custom.colors.primaryMain}
          borderRadius={1.5}
          padding={1.5}
          maxWidth='lg'
          marginX='auto'
          mb={3}
        >
          <AppleHealthIcon />
          <Typography variant='body1' mt={1} textAlign='center'>
            Apple Health data is not currently available.
            <br />
            The patient either hasn’t enabled access or does not have an Apple phone.
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} justifyContent='center' mb={3}>
        {numberOfCards > 0 && (
          <>
            <AppleHealthSleepDialog
              isOpen={!!isDrawerOpen}
              onClose={closeDrawer}
              fetchPatientWaypointTrackingResult={{
                data: fetchPatientWaypointTrackingResult?.data as ListWaypointDetails,
                isFetching: isAppleHealthSleepDataLoading,
              }}
              timeFrame={timeFrame}
              yAxisProps={yAxisProps}
              source={sourceId}
              setSource={onChangeSource}
              sourcesOptions={sourceOptions}
              onChangeTimeFrame={onChangeTimeFrame}
            />
            {sourceId && (
            <AppleHealthChartsWrapper
              timeFrame={timeFrame}
              title='Sleep'
              headerIcon='nights_stay'
              gridSize={numberOfCards === 3 ? 4 : 6}
              isLoading={isTrackingLoading || isAppleHealthSleepDataLoading}
              hasMultipleItems
              showTimeFrame={appleHealthSleepData.length > 0}
              renderChart={(
                <AppleHealthSleepChart
                  timeFrame={timeFrame}
                  averages={appleHealthSleepData}
                  previousPeriodAverages={appleHealthSleepPreviousPeriodAvgData}
                  setSourceId={onChangeSource}
                  sourceId={sourceId}
                  sourceOptions={sourceOptions}
                  openDrawer={openDrawer}
                />
              )}
            />
            )}
            {hasStandHours && (appleHealthActivityData.length > 0 || dataMap.activity) && (
            <AppleHealthChartsWrapper
              timeFrame={timeFrame}
              title='Activity'
              headerIcon='snowshoeing'
              gridSize={numberOfCards === 3 ? 4 : 6}
              isLoading={isTrackingLoading || isAppleHealthActivityDataLoading}
              noData={appleHealthActivityData.length === 0}
              hasMultipleItems
              renderChart={(
                <AppleHealthActivityChart
                  chartData={appleHealthActivityData}
                  chartGoals={appleHealthActivityGoals}
                  yAxisProps={yAxisProps}
                  barSize={barSize}
                  averages={appleHealthActivityAverageData}
                  previousPeriodAverages={appleHealthActivityPreviousPeriodAverageData}
                  timeFrame={timeFrame}
                />
              )}
            />
            )}
            {!hasStandHours && (appleHealthActivityData.length > 0 || dataMap.activity) && (
            <AppleHealthChartsWrapper
              timeFrame={timeFrame}
              title='Active energy'
              headerIcon='snowshoeing'
              gridSize={numberOfCards === 3 ? 4 : 6}
              isLoading={isTrackingLoading || isAppleHealthActivityDataLoading}
              noData={appleHealthActivityData.length === 0}
              renderChart={(
                <AppleHealthActivitySummaryChart
                  chartData={appleHealthActivityData}
                  dataKey={DataKey.ActiveEnergyBurned}
                  units='kCal'
                  average={appleHealthActivityAvgData}
                  yAxisProps={yAxisProps}
                  yAxisDefinition='Calories'
                  barSize={barSize}
                />
              )}
            />
            )}
            {(appleHealthDaylightExposureData.length > 0 || dataMap.daylight) && (
            <AppleHealthChartsWrapper
              timeFrame={timeFrame}
              title='Daylight exposure'
              gridSize={numberOfCards === 3 ? 4 : 6}
              headerIcon='wb_sunny'
              isLoading={isTrackingLoading || isAppleHealthDaylightExposureDataLoading}
              noData={appleHealthDaylightExposureData.length === 0}
              renderChart={(
                <AppleHealthActivitySummaryChart
                  chartData={appleHealthDaylightExposureData}
                  dataKey={DataKey.DaylightExposure}
                  units='Min'
                  average={appleHealthDaylightExposureAvgData}
                  previousPeriodAverage={appleHealthDaylightExposurePreviousPeriodAvgData}
                  yAxisProps={yAxisProps}
                  yAxisDefinition='Duration'
                  barSize={barSize}
                  timeFrame={timeFrame}
                />
              )}
            />
            )}
          </>
        )}

        {(stroopTimeSeriesData.length > 0 || dataMap.stroop) && (
        <AppleHealthChartsWrapper
          timeFrame={timeFrame}
          title='Cognitive performance'
          subTitle='(Stroop test)'
          headerIcon='grain'
          isAppleHealthWaypoint={false}
          gridSize={6}
          showTimeFrame={false}
          isLoading={isStroopTimeSeriesDataLoading}
          noData={stroopTimeSeriesData.length === 0}
          infoIcon
          infoIconText={(
            <Box>
              <Typography mb={2} fontSize={18} fontWeight='normal'>
                {stroopInfoText}
              </Typography>
              <Link
                underline='hover'
                target='_blank'
                href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5388755'
                fontSize={18}
                color={theme.custom.colors.primaryMain}
                fontWeight='normal'
              >
                Learn more
              </Link>
            </Box>
              )}
          renderChart={(
            <AppleHealthActivitySummaryChart
              chartData={stroopTimeSeriesData}
              dataKey={DataKey.Score}
              units=''
              yAxisProps={yAxisProps}
              yAxisDefinition='Score'
              averageValue={stroopTimeSeriesAvgData}
              showAverages={false}
              barSize={barSizeMap[`${timeFrame as TimeFrameOptions}-${6}`]}
            />
              )}
        />
        )}
        {isNewVersionEnabled && somaticScoreTimeSeriesData.length > 0 && (
        <AppleHealthChartsWrapper
          timeFrame={timeFrame}
          title='Somatic score'
          subTitle='(Healthy habits indicator)'
          headerIcon='graphic_eq'
          isAppleHealthWaypoint={false}
          gridSize={6}
          showTimeFrame={false}
          isLoading={isSomaticScoresDataLoading}
          noData={somaticScoreTimeSeriesData.length === 0}
          infoIcon
          infoIconText={(
            <Box>
              <Typography mb={2} fontSize={18} fontWeight='normal'>
                {somaticScoreText}
              </Typography>
            </Box>
              )}
          renderChart={(
            <AppleHealthActivitySummaryChart
              chartData={somaticScoreTimeSeriesData}
              dataKey={DataKey.SomaticScore}
              units=''
              yAxisProps={yAxisProps}
              yAxisDefinition='Score'
              averageValue={somaticScoreTimeSeriesAvgData}
              showAverages={false}
              barSize={barSizeMap[`${timeFrame as TimeFrameOptions}-${6}`]}
              barColor={theme.custom.colors.lightTertiaryMain}
            />
              )}
        />
        )}
      </Grid>
      <WaypointTrackingSection
        patient={patient}
        timeFrame={timeFrame}
        currentWaypoints={currentWaypointData?.waypoints}
        previousWaypoints={previousWaypointData?.waypoints}
        allWaypoints={groupedAllWaypoints}
      />
    </Box>
  );
}
