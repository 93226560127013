import { Stack, Button, Select, MenuItem, InputLabel, Box } from '@mui/material';
import PracticeConnectionCredentials from 'pages/AdminPortal/Tabs/ProviderRequestList/PracticeConnectionCredentials';
import * as React from 'react';
import { ConnectionType, Connection, ConnectionCredentials, Source, Practice } from 'types/Entitites';


const sourceOptions = [
  Source.HealthGorilla,
  Source.Cerner,
];

const typeOptions = [
  ConnectionType.R4,
  ConnectionType.STU3,
];

interface AddConnectionFormProps{
    idx: number,
    connection: Connection,
    addConnection: () => void,
    removeConnection: (idx: number) => void,
    setConnectionData: (e: Connection, idx: number) => void,
    showButton: boolean,
    showRemove: boolean,
    practice: Practice,
}

export default function AddConnectionForm({
  idx, connection, addConnection,
  setConnectionData, showButton,
  practice, removeConnection, showRemove }: AddConnectionFormProps) {
  const handleSubmit = () => {
    addConnection();
  };

  const handleRemoveSubmit = () => {
    removeConnection(idx);
  };

  const setCredentialData = (credentials: ConnectionCredentials[]) => {
    setConnectionData({ ...connection, credentials }, idx);
  };

  return (
    <Stack spacing={2} mx={2}>
      <Box display='flex' alignItems='center' gap={2}>
        <InputLabel sx={{ width: 100 }}>Source</InputLabel>
        <Select
          sx={{ width: 300 }}
          value={connection.source}
          label=''
          onChange={
                ({ target: { value } }) => setConnectionData({ ...connection, source: value }, idx)
            }
        >
          {
            sourceOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))
          }
        </Select>
      </Box>
      <Box display='flex' alignItems='center' gap={2}>
        <InputLabel sx={{ width: 100 }}>Type</InputLabel>
        <Select
          sx={{ width: 300 }}
          value={connection.type}
          label=''
          onChange={
                ({ target: { value } }) => setConnectionData({ ...connection, type: value }, idx)
            }
        >
          {
                typeOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))
            }
        </Select>
      </Box>

      <InputLabel>Credentials</InputLabel>
      <PracticeConnectionCredentials
        connectionId={connection.id}
        credentials={connection.credentials}
        setCredentialData={setCredentialData}
        practice={practice}
      />
      <Box display='flex' gap={2}>
        { !showButton && <Button onClick={handleSubmit} variant='outlined' sx={{ width: 'fit-content' }}> + Add Connection</Button>}
        { showRemove && <Button onClick={handleRemoveSubmit} variant='outlined' sx={{ width: 'fit-content' }}> - Remove Connection</Button>}
      </Box>
    </Stack>
  );
}
