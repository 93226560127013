import * as React from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, Drawer, Grid, Stack, Typography } from '@mui/material';
import {
  getAverageText,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import theme from 'theme';
import ContentLoader from 'components/Common/ContentLoader';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts';
import DrawerHeader from 'pages/Rewards/components/DrawerHeader';
import { DonutLargeOutlined } from '@mui/icons-material';
import { Tabs } from 'components/Common/TabComponent';
import { MoodFactorDomain } from 'pages/Dashboard/types/waypoints.types';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

const indexedColors = [
  '#33739D',
  '#CAA360',
  '#8E4C7D',
  '#4A7F4B',
  '#E5772C',
  '#2EA09A',
  '#4C4C4C',
  '#CC3B3B',
  '#22516B',
  '#A06824',
  '#6D3E64',
  '#1B5E20',
  '#D84F1E',
  '#787878',
  '#BA2A2A',
  '#27827F',
];

const tabs = Object.keys(TimeFrameOptions).map((timeFrameOption) => ({
  header: TimeFrameOptions[timeFrameOption as keyof typeof TimeFrameOptions],
}));

const renderActiveShape = (props: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  payload: { parameter: string, factor: string, Id: string };
  percent: number;
}, onSelectMood?: (id: string, mood: string) => void) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g onClick={() => {
      onSelectMood && props?.payload?.parameter !== 'Other'
    && onSelectMood(props?.payload?.Id, props?.payload?.parameter);
    }}
    >
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fontSize={12}>
        {(payload.parameter ?? payload.factor).split(' ').map((word, index) => (
          <tspan key={word} x={ex + (cos >= 0 ? 1 : -1) * 12} dy={index === 0 ? 0 : 14}>
            {word}
          </tspan>
        ))}
        <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy={16} textAnchor={textAnchor} fontSize={14}>
          {(percent * 100).toFixed(0)}
          %
        </tspan>
      </text>
    </g>
  );
};

function CustomPieChart({
  data,
  count,
  noData,
  isLoading,
  onSelectMood,
}: {
  data: (Metric | MoodFactorDomain)[];
  count: number;
  onSelectMood?: (moodId: string, mood: string) => void;
  noData: boolean;
  isLoading: boolean;
}): JSX.Element {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const onPieEnter = (__: {Id :string}, index: number) => {
    setActiveIndex(index);
  };

  return (
    <Box>
      <style>
        {`
          .recharts-pie-sector:focus {
            outline: none; /* Disable outline on pie slice click */
          }
        `}
      </style>

      <ResponsiveContainer width='100%' height={noData ? 0 : 240}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={(props) => renderActiveShape(props, onSelectMood)}
            data={data}
            cx='50%'
            cy='50%'
            innerRadius={47}
            outerRadius={55}
            fill='#27827F'
            paddingAngle={3}
            dataKey='count'
            onMouseOver={onPieEnter}
          >
            {data.map((entry, index) => {
              const actualEntry = (data[index] as Metric).parameter
              ?? (data[index] as MoodFactorDomain).factor;
              return (
                <Cell
                  key={actualEntry}
                  fill={actualEntry === 'Other' ? theme.custom.colors.lightTextSecondary : indexedColors[index % indexedColors.length]}
                  stroke={actualEntry === 'Other' ? theme.custom.colors.lightTextSecondary : indexedColors[index % indexedColors.length]}
                  strokeWidth={0.5}
                />
              );
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <ContentLoader
        isFetching={isLoading}
        isError={false}
        noDataText={noData ? `No ${onSelectMood ? 'moods' : 'factors'} exists for the selected timeframe` : ''}
        minHeight={100}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box textAlign='left' width='50%'>
            {data.map((metric, index) => {
              const actualEntry = (metric as Metric).parameter
             ?? (metric as MoodFactorDomain).factor;
              return Math.round((metric.count / count) * 100) > 0 ? (
                <Box
                  key={actualEntry}
                  mx={1}
                  display='flex'
                  alignItems='baseline'
                  ml={2}
                  mb={2}
                >

                  <Box
                    sx={{
                      minWidth: '8px',
                      minHeight: '8px',
                      borderRadius: '50%',
                      backgroundColor: actualEntry === 'Other' ? theme.custom.colors.lightTextSecondary : indexedColors[index % indexedColors.length],
                      marginRight: '5px',
                    }}
                  />
                  <Box>
                    <Typography variant='body1' fontWeight='normal' color={actualEntry === 'Other' ? theme.custom.colors.lightTextSecondary : indexedColors[index % indexedColors.length]}>
                      {Math.round(((metric as Metric).count / count) * 100)}
                      %
                      {' - '}
                      {actualEntry}
                    </Typography>
                    {(metric?.domains?.length ?? 0) > 0
                   && (
                   <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                     Specifically
                     {metric?.domains?.reduce((acc, d) => `${acc}, ${d.name.toLowerCase()}`, '')}
                   </Typography>
                   )}

                    {actualEntry === 'Other' && ((metric as Metric)?.parameters?.length ?? 0) > 0 && (
                    <Typography variant='body1' fontWeight='normal' color={theme.custom.colors.lightTextSecondary}>
                      {(metric as Metric).parameters}
                    </Typography>
                    )}
                    <Typography variant='body1' fontWeight='normal' color={indexedColors[index % indexedColors.length]} />
                  </Box>
                </Box>
              ) : null;
            })}
          </Box>
        </Box>
      </ContentLoader>
    </Box>
  );
}

type Metric = {
  parameter?: string;
  count: number;
  parameters?: string;
  factor?: string;
  domains?: {
    id: string;
    name: string;
  }[]
};

function RadarChart({
  label,
  data,
  count,
}: {
  label: string;
  data: (Metric | MoodFactorDomain)[];
  count: number;
}): JSX.Element {
  return (
    <Box mt={3}>
      <Typography variant='subtitle1' fontWeight={700} mb={1} color={theme.custom.colors.lightTextPrimary}>
        {label}
      </Typography>

      <Grid container spacing={3}>
        {data.map((_, index) => (
          Math.round((data[index].count / count) * 100) > 0 ? (
            <Grid
              key={((data[index] as Metric).parameter ?? data[index].factor)}
              item
              xs={4}
            >
              <Typography variant='body1' fontWeight='normal'>
                {((data[index] as Metric).parameter ?? data[index].factor)}
              </Typography>

              <Typography variant='h5' fontWeight='normal' color={indexedColors[index] ?? indexedColors[5]}>
                {Math.round((data[index].count / count) * 100)}
                %
              </Typography>
            </Grid>
          ) : null
        ))}
      </Grid>
    </Box>
  );
}

type Data = {
  mood: Metric[];
  factors: MoodFactorDomain[];
  factorsLoading: boolean;
  moodsLoading: boolean;
};

interface LoggedMoodsProps {
  timeFrame: string;
  data: Data;
  noData: boolean;
  onChangeTimeFrame: (v: string) => void;
  onSelectMood: (moodId: string)=> void;
}

export default function LoggedMoods({
  timeFrame: selectedTimeFrame,
  data,
  noData,
  onChangeTimeFrame: setSelectedTimeFrame,
  onSelectMood,
}: LoggedMoodsProps): JSX.Element {
  const timeFrameString = getAverageText(selectedTimeFrame, false, true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [moodName, setMoodName] = React.useState('');
  const onShowMore = () => {
    trackMixpanelEvent(MixpanelEventName.VIEW_CHARTS_CLICKED);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    onSelectMood('');
    setMoodName('');
  };

  const timeFrameValues = React.useMemo(() => Object.values(TimeFrameOptions), []);
  const timeFrame = React.useMemo(
    () => timeFrameValues.findIndex((value) => value === selectedTimeFrame),
    [selectedTimeFrame, timeFrameValues],
  );

  const adjustedMoods = React.useMemo(() => {
    const totalMoodsCount = data.mood.reduce((acc, curr) => acc + curr.count, 0);
    const moods = data.mood.slice(0, 5);
    const otherMoods = data.mood.slice(5);
    let otherMoodsCount = otherMoods.reduce((acc, curr) => acc + curr.count, 0);
    while (otherMoods.length > 0) {
      const lastMoodPercentage = Math.round(
        (moods[moods.length - 1].count / totalMoodsCount) * 100,
      );
      const otherMoodsPercentage = Math.round((otherMoodsCount / totalMoodsCount) * 100);
      if (otherMoodsPercentage <= lastMoodPercentage) {
        break;
      }

      const moodToMove = otherMoods.shift();
      otherMoodsCount -= moodToMove?.count ?? 0;
      if (moodToMove) {
        moods.push(moodToMove);
      }
    }

    return {
      list: [
        ...moods,
        ...(otherMoodsCount > 0 ? [{
          parameter: 'Other',
          count: otherMoodsCount,
          parameters: otherMoods.reduce(
            (acc, mood) => `${acc ? `${acc}, ` : ''}${mood.parameter}`,
            '',
          ),
        }] : []),
      ],
      count: totalMoodsCount,
    };
  }, [data.mood]);

  const adjustedSituations = React.useMemo(() => {
    const totalSituationsCount = data.factors.reduce((acc, curr) => acc + curr.count, 0);
    const situations = data.factors.slice(0, 6);
    const otherSituations = data.factors.slice(6);
    let otherSituationsCount = otherSituations.reduce((acc, curr) => acc + curr.count, 0);
    while (otherSituations.length > 0) {
      const lastSituationPercentage = Math.round(
        (situations[situations.length - 1].count / totalSituationsCount) * 100,
      );
      const otherSituationsPercentage = Math.round(
        (otherSituationsCount / totalSituationsCount) * 100,
      );
      if (otherSituationsPercentage <= lastSituationPercentage) {
        break;
      }

      const situationToMove = otherSituations.shift();
      otherSituationsCount -= situationToMove?.count ?? 0;
      if (situationToMove) {
        situations.push(situationToMove);
      }
    }

    return {
      list: [
        ...situations.sort((a, b) => b.count - a.count),
        ...(otherSituationsCount > 0 ? [{
          parameter: 'Other',
          count: otherSituationsCount,
          parameters: otherSituations.reduce(
            (acc, situation) => `${acc ? `${acc}, ` : ''}${situation.factor}`,
            '',
          ),
        }] : []),
      ],
      count: totalSituationsCount,
    };
  }, [data.factors]);


  const onMoodChange = (id: string, mood: string) => {
    trackMixpanelEvent(MixpanelEventName.MOOD_FILTER_CLICKED, {
      mood,
      id,
    });

    onSelectMood(id);
    setMoodName(mood);
  };
  const handleClearFilters = () => {
    trackMixpanelEvent(MixpanelEventName.CLEAR_FILTERS_CLICKED);
    onSelectMood(''); setMoodName('');
  };

  return (
    <Grid item xs={12} md={4} key='mood-card'>
      <Card
        sx={{
          height: '100%',
          borderRadius: '5px',
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiCardContent-root:last-child': {
            paddingBottom: 2,
          },
        }}
      >
        <CardHeader
          sx={{
            borderRadius: '5px 5px 0 0',
            paddingBottom: 0,
            '& .MuiCardHeader-action': {
              margin: 0,
              alignSelf: 'center',
              cursor: 'pointer',
            },
          }}
          title={(
            <Box display='flex' alignItems='center'>
              <Typography variant='h5' fontWeight='normal'>
                Details
                <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                  {timeFrameString}
                </Typography>
              </Typography>
            </Box>
          )}
        />
        <CardContent sx={{ paddingTop: 0, flex: 1 }}>
          <Drawer
            anchor='right'
            open={isOpen}
            onClose={onClose}
            sx={{ zIndex: theme.zIndex.drawer + 2 }}
            PaperProps={{ sx: { maxWidth: 'lg', width: '100%' } }}
            data-testid='rewards-drawer'
          >
            <Box role='grid' display='flex' flexDirection='column' overflow='hidden'>
              <DrawerHeader title='Mood details' onClose={onClose} showBackIcon={false} />
              <Divider />
              <Box
                display='flex'
                alignItems='center'
                bgcolor='#E9E9E9'
                justifyContent='flex-end'
                mx={2}
                my={3}
              >
                <Typography color='#4C4C4C' marginRight={2}>
                  Data from past:
                </Typography>
                <Tabs
                  value={timeFrame}
                  setValue={(value) => {
                    const timeFrameValue = timeFrameValues[value as number];
                    trackMixpanelEvent(MixpanelEventName.DATERANGE_CLICKED, {
                      timeFrameValue,
                      clickedFrom: 'Moods and activities',
                    });
                    onSelectMood('');
                    setMoodName('');
                    setSelectedTimeFrame(timeFrameValue);
                  }}
                  tabContext={tabs}
                  variant='standard'
                  activeStyles={{
                    backgroundColor: '#FFF',
                    maxHeight: 24,
                  }}
                />
              </Box>
              <Grid container padding={2} flex={1} overflow='scroll' spacing={2}>
                <Grid item xs={6} textAlign='center'>
                  <Typography variant='h6'>
                    Moods
                  </Typography>
                  <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                    (click section to filter factors)
                  </Typography>
                  <Typography
                    component='span'
                    variant='body1'
                    color={theme.custom.colors.primaryMain}
                    visibility={moodName ? 'visible' : 'hidden'}
                    sx={{
                      ':hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      },
                    }}
                    onClick={handleClearFilters}
                  >
                    clear filters
                  </Typography>

                  <CustomPieChart
                    data={adjustedMoods.list}
                    count={adjustedMoods.count}
                    onSelectMood={onMoodChange}
                    isLoading={data?.moodsLoading}
                    noData={noData}
                  />
                </Grid>
                <Grid item xs={6} textAlign='center'>
                  <Typography variant='h6'>
                    Factors
                  </Typography>
                  <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} visibility={moodName ? 'visible' : 'hidden'} mb={3}>
                    Mood -
                    {' '}
                    {moodName}
                  </Typography>
                  <CustomPieChart
                    data={adjustedSituations.list}
                    count={adjustedSituations.count}
                    isLoading={data?.factorsLoading}
                    noData={noData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Drawer>
          <ContentLoader
            isFetching={data?.factorsLoading || data?.moodsLoading}
            isError={false}
            noDataText={noData ? 'No data exists for the selected timeframe' : ''}
            minHeight={100}
          >
            <Stack display='flex' flexDirection='column' height='100%'>
              <RadarChart label='Moods' data={adjustedMoods.list.slice(0, 6)} count={adjustedMoods.count} />
              <RadarChart label='Factors' data={adjustedSituations.list.slice(0, 6)} count={adjustedSituations.count} />
              <Box textAlign='center' width='100%' marginTop='auto'>
                <Button
                  onClick={onShowMore}
                  variant='contained'
                  sx={{ mt: 2 }}
                  startIcon={(
                    <DonutLargeOutlined
                      fontSize='large'
                    />
              )}
                >
                  VIEW CHARTS
                </Button>
              </Box>
            </Stack>
          </ContentLoader>
        </CardContent>
      </Card>
    </Grid>
  );
}
