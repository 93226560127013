import Home from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Summarize from '@mui/icons-material/Summarize';
import { NavTreeIds, NavTreeModel, UserRole } from 'types/NavTreeModel';
import * as spaUrl from 'utils/spaUrls';

const NavTree: NavTreeModel = [
  {
    name: 'Home',
    icon: Home,
    spaUrl: spaUrl.mainNav.dashboard,
    id: NavTreeIds.HOME,
    roles: [
      UserRole.PROVIDER,
      UserRole.STAFF,
      UserRole.CHIEF_ADMIN,
      UserRole.SUPER_ADMIN,
      UserRole.BILLING_ADMIN,
    ],
  },
  {
    name: 'Add a patient',
    icon: PersonAddIcon,
    spaUrl: spaUrl.mainNav.addNewPatient,
    id: NavTreeIds.ADD_PATIENT,
    roles: [
      UserRole.PROVIDER,
      UserRole.STAFF,
      UserRole.CHIEF_ADMIN,
      UserRole.SUPER_ADMIN,
    ],
  },
  {
    name: 'Reports',
    icon: Summarize,
    spaUrl: spaUrl.mainNav.reports,
    id: NavTreeIds.REPORTS,
    roles: [UserRole.BILLING_ADMIN],
  },
];


export default NavTree;
