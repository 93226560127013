import SendIcon from '@mui/icons-material/Send';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Alert, Box, CardContent, Typography, capitalize } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import { ONBOARDING_STATUS } from 'pages/Dashboard/enums';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import React from 'react';
import { googlePhoneNumberToPhoneNumberString } from 'utils/phoneUtils';
import ResendInvite from 'pages/Dashboard/components/ResendInvite';
import ResolveInvite from 'pages/Dashboard/components/ResolveInvite';
import { ScheduleSend } from '@mui/icons-material';
import { formatEmail } from 'utils/appUtils';
import theme from 'theme';

interface IProps {
  details: PatientObj;
  showProvider: boolean;
}

export default function PendingPatientCard({ details, showProvider }: IProps) {
  const email = formatEmail(details?.email);
  const phone = details?.phoneNumber?.number
    ? googlePhoneNumberToPhoneNumberString(details.phoneNumber)
    : '';

  const title = () => (
    <Box display='flex' flexDirection='column'>
      {showProvider && (
        <Typography variant='h6'>{`${capitalize(details.provider?.firstName ?? '')} ${capitalize(details.provider?.lastName ?? '')}`}</Typography>
      )}
      <Typography variant='h6' className='fs-mask'>{phone || email}</Typography>
    </Box>
  );

  const isPendingInviteDelivery = ONBOARDING_STATUS.PENDING_INVITE_DELIVERY
    === (details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  const isPendingPatientSignup = ONBOARDING_STATUS.PENDING_PATIENT_SIGNUP
    === (details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  const isOnboardingStarted = ONBOARDING_STATUS.ONBOARDING_STARTED
    === (details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  const isPreparingInviteDelivery = ONBOARDING_STATUS.PREPARING_INVITE_DELIVERY
    === (details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  const isDeliveryFailed = ONBOARDING_STATUS.DELIVERY_FAILED
    === (details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  const cardContent = React.useMemo(() => {
    if (isPendingInviteDelivery) {
      return (
        <Box display='flex' gap='12px'>
          <SendIcon
            fontSize='small'
            sx={{ color: theme.custom.colors.lightTextSecondary, marginTop: '2px' }}
          />
          <Typography variant='body1'>Sending invite</Typography>
        </Box>
      );
    }
    if (isPendingPatientSignup || isOnboardingStarted) {
      return (
        <Box display='flex' gap='12px'>
          <AssignmentIcon
            fontSize='small'
            sx={{ color: theme.custom.colors.lightTextSecondary, marginTop: '2px' }}
          />
          <Typography variant='body1'>Awaiting patient signup</Typography>
        </Box>
      );
    }
    if (isDeliveryFailed) {
      return (
        <Box display='flex' gap='12px' sx={{ width: '100%' }}>
          <Alert variant='outlined' severity='error' sx={{ width: '100%' }}>
            Invite delivery failed
          </Alert>
        </Box>
      );
    }
    if (isPreparingInviteDelivery) {
      return (
        <Box display='flex' gap='12px'>
          <ScheduleSend
            fontSize='small'
            sx={{ color: theme.custom.colors.lightTextSecondary, marginTop: '2px' }}
          />
          <Typography variant='body1'>Preparing to send invite</Typography>
        </Box>
      );
    }
    return null;
  }, [
    isDeliveryFailed,
    isPendingInviteDelivery,
    isPendingPatientSignup,
    isOnboardingStarted,
    isPreparingInviteDelivery,
  ]);

  const cardActions = React.useMemo(() => {
    if (isPendingInviteDelivery || isPendingPatientSignup || isOnboardingStarted) {
      return <ResendInvite patient={details} />;
    }
    if (isDeliveryFailed) {
      return <ResolveInvite patient={details} />;
    }
    return null;
  }, [
    details,
    isDeliveryFailed,
    isPendingInviteDelivery,
    isPendingPatientSignup,
    isOnboardingStarted,
  ]);

  return (
    <Card
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        sx={{ wordBreak: 'break-word' }}
        title={title()}
        className='fs-mask'
        {...(phone && email
          ? {
            subheader: email,
            subheaderTypographyProps: {
              variant: 'body1',
              color: theme.custom.colors.lightTextSecondary,
            },
            titleTypographyProps: { variant: 'h6' },
          }
          : {})}
      />
      <CardContent>{cardContent}</CardContent>
      <CardActions disableSpacing sx={{ padding: '16px', mt: 'auto' }}>
        {cardActions}
      </CardActions>
    </Card>
  );
}
