import React, { useCallback, useEffect } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Controller, Control, RegisterOptions, FieldValues } from 'react-hook-form';
import { InputBaseComponentProps, useTheme } from '@mui/material';

interface InputProps {
  name: string;
  control: Control;
  label: string;
  textFieldProps?: TextFieldProps;
  required?: boolean;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  inputProps?: InputBaseComponentProps | undefined;
  hideLastPass?: boolean;
  isMasked?:boolean
}

export default function Input({
  name,
  control,
  label,
  textFieldProps,
  required,
  rules = {},
  inputProps,
  hideLastPass,
  isMasked,
}: InputProps) {
  const theme = useTheme();

  const updateLastPassVisibility = useCallback((display: string) => {
    const lastPassRoot = document.querySelector('[data-lastpass-root]') as HTMLElement;
    if (lastPassRoot) {
      lastPassRoot.style.display = display;
    }
  }, []);

  const onFocused = () => {
    updateLastPassVisibility(hideLastPass ? 'none' : 'block');
  };

  useEffect(() => () => updateLastPassVisibility('block'), [updateLastPassVisibility]);
  const className = isMasked ? 'fs-mask' : '';
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          autoComplete='off'
          FormHelperTextProps={{ style: { fontSize: theme.typography.body2.fontSize } }}
          fullWidth
          sx={{
            'div[data-lastpass-icon-root]': {
              display: 'none',
            },
          }}
          label={label}
          variant='outlined'
          required={required}
          inputProps={inputProps}
          onFocus={onFocused}
          {...textFieldProps}
          className={className}
        />
      )}
    />
  );
}
