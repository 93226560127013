// eslint-disable-next-line import/no-cycle
import { AddressObj, SourceTypeMap } from 'pages/Dashboard/types';
import { GoogleDate } from 'utils/dateUtils';

export enum MentalHealthProviderCategory {
  MENTAL_HEALTH_PROVIDER_CATEGORY_UNSPECIFIED = 'MENTAL_HEALTH_PROVIDER_CATEGORY_UNSPECIFIED',
  MENTAL_HEALTH_PROVIDER_CATEGORY_PSYCHIATRIST = 'MENTAL_HEALTH_PROVIDER_CATEGORY_PSYCHIATRIST',
  MENTAL_HEALTH_PROVIDER_CATEGORY_THERAPIST = 'MENTAL_HEALTH_PROVIDER_CATEGORY_THERAPIST',
}


export interface Providers {
  id: string;
  firstName: string;
  lastName: string;
  designation: string;
  npiNumber: number;
  middleName?: string;
  credential?: string;
  namePrefix?: string;
  nameSuffix?: string;
  license?: string;
  licenseState?: string;
  taxonomyGroup?: string;
  taxonomyCode?: string;
  gender?: string;
  status?: string;
  enumerationDate?: GoogleDate;
  certificationDate?: GoogleDate;
  email?: string;
}

interface Organization {
  id: string;
  name: string;
  address: AddressObj;
}

export interface Provider {
  id: string;
  provider: Providers;
  organization: Organization;
  startDate: GoogleDate;
  endDate: GoogleDate | null;
  current: boolean;
  source: keyof typeof SourceTypeMap;
}

export interface MentalHealthProvider extends Provider {
  category: MentalHealthProviderCategory;
}

export interface ProvidersAPIResponse {
  patientProviders: Provider[];
}

export interface ListPrimaryCareProvidersAPIResponse {
  primaryCareProviders: Provider[];
}

export interface ListMentalHealthProvidersAPIResponse {
  mentalHealthProviders: MentalHealthProvider[];
}

export interface PracticeProvider {
  id: string;
  firstName: string;
  lastName: string;
  designation: string;
  npiNumber: string;
}

export interface PracticeProvidersListResponse {
  providers: PracticeProvider[]
}

export enum ProviderDesgination {
  PROVIDER_DESIGNATION_UNSPECIFIED = 'PROVIDER_DESIGNATION_UNSPECIFIED',
  PROVIDER_DESIGNATION_CLINICAL_SOCIAL_WORKER = 'PROVIDER_DESIGNATION_CLINICAL_SOCIAL_WORKER',
  PROVIDER_DESIGNATION_COUNSELOR = 'PROVIDER_DESIGNATION_COUNSELOR',
  PROVIDER_DESIGNATION_NURSE_PRACTITIONER = 'PROVIDER_DESIGNATION_NURSE_PRACTITIONER',
  PROVIDER_DESIGNATION_OFFICE_STAFF = 'PROVIDER_DESIGNATION_OFFICE_STAFF',
  PROVIDER_DESIGNATION_PSYCHIATRIST = 'PROVIDER_DESIGNATION_PSYCHIATRIST',
  PROVIDER_DESIGNATION_THERAPIST = 'PROVIDER_DESIGNATION_THERAPIST',
  PROVIDER_DESIGNATION_PRIMARY_CARE_PROVIDER = 'PROVIDER_DESIGNATION_PRIMARY_CARE_PROVIDER',
  PROVIDER_DESIGNATION_BILLING_ADMIN = 'PROVIDER_DESIGNATION_BILLING_ADMIN'
}

export interface ProviderConsentResponse {
  consent: boolean;
}

export interface AssociateProviderPayload {
  staff_id: string,
  practice_id: string,
  provider:{ id: string }[],
  associate?: boolean,
}
