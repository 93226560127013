import { CacheKeysModel } from 'types/CacheKeysModel';

export const CacheKeys: CacheKeysModel = {
  enableDarkMode: 'enable-dark-mode',
  orgId: 'org-id',
  userId: 'user-id',
  providerId: 'super-admin-selected-provider',
};

export const LogoUrl = {
  sm: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/favicon_w47qqz.ico',
  lg: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/headlamp-health-logo-light-bg_tsykhe.png',
};

export const NotSpecified = 'Not specified';

export const WelcomeExplorerActionId = 'welcome-action';

export enum MixpanelEventName {
  SEE_ANSWERS_CLICKED = 'Rating scales see answers button clicked',
  PATIENT_NAME_CLICKED = 'Rating scales patient name link clicked',
  DOWNLOAD_PDF_CLICKED = 'Rating scales download PDF button clicked',
  FILTERS_APPLIED = 'Rating scales filters applied',
  EXPORT_DATA_CLICKED = 'Export data clicked',
  SCALES_SUGGESTED = 'Patient overview scales suggested',
  SCALE_CLICKED = 'Patient overview rating scale link clicked',
  ADDED_NOTE_ON_SCALE = 'Patient overview rating scale note added',
  REPORTS_ROUTE_VISITED = 'Reports route visited',
  AH_SLEEP_CHART_SOURCE_CHANGED = 'Apple Health sleep chart source changed',
  AH_SLEEP_CHART_VIEW_DETAILS_CLICKED = 'Apple Health sleep chart view details clicked',
  AH_SLEEP_CHART_CATEGORY_SELECTED = 'Apple Health sleep chart category selected',
  MOOD_AND_WAYPOINT_TIME_FRAME_CHANGED = 'Mood and waypoint time frame changed',

  VIEW_MORE_BUTTON_CLICK = 'View more button clicked',
  ADD_PATIENT_NEW_BUTTON_CLICK = 'Add Patient New button clicked',
  APPLIED_SORTING = 'Applied Sorting',
  QUERY_SEARCHED = 'Query Searched',
  STATUS_FILTER_SELECTED = 'Status filter selected',
  PROVIDER_FILTER_SELECTED = 'Provider filter selected',
  PROVIDER_METADATA_LOADED = 'Provider metadata loaded',
  NEXT_PAGE_CLICK = 'Next page click',
  PREV_PAGE_CLICK = 'Previous page click',
  PATIENT_QUICK_VIEW_OPENED = 'Patient quick view opened',
  PAGE_CHANGED = 'Page changed',
  ROWS_PER_PAGE_CHANGED = 'Rows per page changed',

  MEDICATION_CLICKED = 'Medication Clicked',
  DIAGNOSIS_CLICKED = 'Diagnosis Clicked',
  SHOW_MORE_MEDICATION_CLICKED = 'Show More Medication Clicked',
  HISTORY_DRAWER_CLOSED = 'History Drawer Closed',
  HISTORY_SHOW_CHART_CLICKED = 'History Show Chart Clicked',
  HISTORY_SHOW_TIMELINE_CLICKED = 'History Show Timeline Clicked',
  SHOW_MORE_DIAGNOSIS_CLICKED = 'Show More Diagnosis Clicked',

  VIEW_CHARTS_CLICKED = 'Moods and activities - View charts clicked',
  CLEAR_FILTERS_CLICKED = 'Moods and activities - Clear filters clicked',
  MOOD_FILTER_CLICKED = 'Moods and activities - Mood filter clicked',
  DATERANGE_CLICKED = 'Moods and activities - Date range clicked',

  POINT_BALANCE_ICON_CLICKED = 'Point balance icon clicked',
  WAYS_TO_EARN_TAB_CLICKED = 'Ways to earn tab clicked',
  REWARDS_TAB_CLICKED = 'Rewards tab clicked',
  TRANSACTION_HISTORY_TAB_CLICKED = 'Transaction history tab clicked',
  REFER_A_CLINICIAN_TO_HEADLAMP_LINK_CLICKED = 'Refer a clinician to Headlamp link clicked',
  REDEEM_LINK_CLICKED = 'Email link redeem@headlamp.com clicked',
  FEEDBACK_LINK_CLICKED = 'Email link feedback@headlamp.com clicked',
  SEND_REFERRAL_BUTTON_CLICKED = 'Send Referral button clicked',
  SEND_ANOTHER_REFERRAL_BUTTON_CLICKED = 'Send Another Referral button clicked',
  HEADLAMP_GIVES_BACK_PANEL_CLOSED = 'Headlamp gives back panel closed',

  HEALTH_RECORDS_REQUESTED = 'Health Records Requested',
  APP_CLOSED = 'Provider app closed',
  TERMS_OF_SERVICE_LINK_CLICKED = 'Terms of Service link clicked',
  BAA_LINK_CLICKED = 'BAA link clicked',
  PRIVACY_POLICY_LINK_CLICKED = 'Privacy Policy link clicked',
  TERMS_AND_PRIVACY_CONTINUE_BUTTON_CLICKED = 'Terms & Privacy continue button clicked',
  EDIT_WAYPOINTS_BUTTON_CLICK = 'Edit waypoints button clicked',
  EDIT_WAYPOINTS_PANEL_CLOSED = 'Edit waypoints panel closed',
  SEND_SUGGESTIONS_BUTTON_CLICK = 'Send Suggestions button clicked',
  SEND_SUGGESTIONS_CONFIRMATION_BUTTON_CLICK = 'Send Suggestions confirmation button clicked',
  SEND_SUGGESTIONS_CANCEL_BUTTON_CLICK = 'Send Suggestions cancel button clicked',
  REVOKE_SUGGESTIONS_BUTTON_CLICK = 'Revoke suggestions button clicked',
  REVOKE_SUGGESTIONS_CONFIRMATION_BUTTON_CLICK = 'Revoke suggestions confirmation button clicked',
  REVOKE_SUGGESTIONS_CANCEL_BUTTON_CLICK = 'Revoke Suggestions cancel button clicked',
}
