import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';


interface UpdateAndResendActivationEmailProps {
  email?: string;
  setProviderEmail?: (email: string) => void;
}

function UpdateAndResendActivationEmail({
  email,
  setProviderEmail,
}: UpdateAndResendActivationEmailProps) {
  const [emailInput, setEmailInput] = useState(email);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailInput(e.target.value);
  };

  const isEmailInvalid = emailInput !== undefined && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput);

  return (
    <Box paddingY={1}>
      <TextField
        placeholder='Enter email'
        label='Provider email'
        type='email'
        value={emailInput}
        onChange={(e) => {
          handleEmailChange(e);
          const emailValue = e.target.value;
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          setProviderEmail && setProviderEmail(emailRegex.test(emailValue) ? emailValue : '');
        }}
        variant='outlined'
        sx={{ width: 300 }}
        error={isEmailInvalid}
        helperText={isEmailInvalid ? 'Invalid email address' : ''}
      />
    </Box>
  );
}

export default UpdateAndResendActivationEmail;
