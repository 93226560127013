import * as React from 'react';
import { Autocomplete as MuiAutocomplete, TextField, Typography } from '@mui/material';

export type Option = {
  label: string;
  value: string;
} | null;

interface AutocompleteProps {
  label: string;
  value: Option;
  options: Option[];
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
  setValue: (value: (Option)) => void;
  className?: string;
}

export default function Autocomplete({
  label,
  options,
  value,
  loading = false,
  disabled = false,
  testId,
  setValue,
  className,
}: AutocompleteProps) {
  return (
    <MuiAutocomplete
      value={value || null}
      onChange={(__, newValue) => {
        setValue(newValue);
      }}
      size='small'
      options={options}
      renderOption={(props, option) => (
        <Typography {...props} key={option?.value} className='fs-mask' sx={{ pl: 1, pb: 1, cursor: 'pointer' }}>
          {option?.label}
        </Typography>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
      multiple={false}
      fullWidth
      limitTags={1}
      loading={loading}
      disabled={disabled}
      data-testid={testId}
      className={className}
    />
  );
}
