import { Box, BoxProps, Tab, Tabs } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DoctorText from 'pages/Dashboard/components/DoctorText';
import { TabLabels } from 'pages/Dashboard/components/MedicationCard';
import NoData from 'pages/Dashboard/components/NoData';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { Diagnosis, DiagnosisCategory, DiagnosisCode } from 'pages/Dashboard/types/diagnosis.types';
import * as React from 'react';
import { googleDateToDateString } from 'utils/dateUtils';
import { findPreferredDiagnosisCode } from 'utils/patientUtils';
import theme from 'theme';

export interface DiagnosisCardProps extends ShowMoreDrawerChildProps {
  diagnosis?: Diagnosis[];
  showTabs?: boolean;
  drawerTabIndex?: number;
  onShowMoreDiagnosis?: (d: Diagnosis[], idx?: number) => void;
}

interface DiagnosisDetailsProps extends BoxProps {
  diagnosis: Diagnosis[];
  children: React.ReactNode;
}

function getDiagnosisDescription(currentDiagnosisCode: DiagnosisCode, description = '') {
  if (!currentDiagnosisCode?.code && !description) {
    return '';
  }
  return ` ${currentDiagnosisCode?.code ? `${currentDiagnosisCode?.code} ` : ''}(${description})`;
}

function DiagnosisDetails({ diagnosis, children, ...boxProps }: DiagnosisDetailsProps) {
  const unknownDateDiagnosis = diagnosis.filter((current) => !current.started);

  return (
    <Box {...boxProps}>
      {diagnosis.length ? (
        <>
          {diagnosis.map((currentDiagnosis) => {
            if (!currentDiagnosis.started) return null;
            const diagnosisCode = findPreferredDiagnosisCode(currentDiagnosis.diagnosis.codes);
            return (
              <React.Fragment key={`current-regimen-${currentDiagnosis.id}`}>
                <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} className='fs-mask'>
                  {googleDateToDateString(currentDiagnosis.started)}
                </Typography>
                {!!diagnosisCode && (
                <Typography mb={2}>
                  {getDiagnosisDescription(diagnosisCode, currentDiagnosis.diagnosis?.displayName)}
                </Typography>
                )}
                <DoctorText doctor={currentDiagnosis.doctor} />
              </React.Fragment>
            );
          })}
          {unknownDateDiagnosis.length ? (
            <>
              <Typography mb={2} variant='body1' color={theme.custom.colors.lightTextSecondary}>
                Unknown dates
              </Typography>
              {unknownDateDiagnosis.map((unknownDiagnosis) => {
                const unknownDiagnosisCode = findPreferredDiagnosisCode(
                  unknownDiagnosis.diagnosis.codes,
                );
                if (!unknownDiagnosisCode) {
                  return null;
                }
                return (
                  <Typography mb={2} key={`unknown-diagnosis-${unknownDiagnosis?.id}`}>
                    {getDiagnosisDescription(
                      unknownDiagnosisCode,
                      unknownDiagnosis.diagnosis?.displayName,
                    )}
                  </Typography>
                );
              })}
            </>
          ) : null}
          {children}
        </>
      ) : (
        <NoData />
      )}
    </Box>
  );
}

export default function DiagnosisCard({
  diagnosis = [],
  showTabs,
  drawerTabIndex,
  drawerMode,
  closeDrawer,
  drawerItem,
  onShowMoreDiagnosis,
}: DiagnosisCardProps): JSX.Element | null {
  const [value, setValue] = React.useState(0);

  const datedDiagnosis = diagnosis.filter((currentDiagnosis) => currentDiagnosis.started);

  const nonDatedDiagnosis = diagnosis
    .filter((currentDiagnosis) => !currentDiagnosis.started)
    .sort((a, b) => getDiagnosisDescription(a.diagnosis.codes[0], a.diagnosis.displayName)
      .localeCompare(
        getDiagnosisDescription(b.diagnosis.codes[0], b.diagnosis.displayName),
      ));

  const sortedDiagnosis = [...datedDiagnosis, ...nonDatedDiagnosis];

  const slicedDiagnosis = sliceForShowMore<Diagnosis>(sortedDiagnosis, !drawerMode);

  const psychDiagnosis = diagnosis?.filter(
    (currentDiagnosis) => currentDiagnosis.category === DiagnosisCategory.DIAGNOSIS_CATEGORY_PSYCH,
  );
  const otherDiagnosis = diagnosis?.filter(
    (currentDiagnosis) => currentDiagnosis.category !== DiagnosisCategory.DIAGNOSIS_CATEGORY_PSYCH,
  );

  const slicedPsychDiagnosis = sliceForShowMore<Diagnosis>(psychDiagnosis, !drawerMode);
  const slicedOtherDiagnosis = sliceForShowMore<Diagnosis>(otherDiagnosis, !drawerMode);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (drawerMode) {
      setValue(drawerTabIndex || 0);
    }
  }, [drawerTabIndex, drawerMode]);
  const { Icon } = HealthRecordCategories.diagnosis.value;
  return (

    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='current-regimen-diagnosis'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Diagnoses'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && (
          <ShowMoreDrawerTitle
            closeDrawer={closeDrawer as VoidFunction}
            drawerItem={drawerItem as string}
          />
        )}
        <CardContent>
          {showTabs ? (
            <>
              <Box mb={2}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label={TabLabels.PSYCH} />
                  <Tab label={TabLabels.OTHER} />
                </Tabs>
              </Box>
              <DiagnosisDetails hidden={value !== 0} diagnosis={slicedPsychDiagnosis}>
                <ShowMoreButton
                  isDrawer={drawerMode}
                  onShowMore={() => onShowMoreDiagnosis?.(diagnosis, value)}
                  items={psychDiagnosis}
                />
              </DiagnosisDetails>
              <DiagnosisDetails hidden={value !== 1} diagnosis={slicedOtherDiagnosis}>
                <ShowMoreButton
                  isDrawer={drawerMode}
                  onShowMore={() => onShowMoreDiagnosis?.(diagnosis, value)}
                  items={otherDiagnosis}
                />
              </DiagnosisDetails>
            </>
          ) : (
            <DiagnosisDetails diagnosis={drawerMode ? diagnosis : slicedDiagnosis}>
              <ShowMoreButton
                isDrawer={drawerMode}
                onShowMore={() => onShowMoreDiagnosis?.(diagnosis)}
                items={diagnosis}
              />
            </DiagnosisDetails>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
